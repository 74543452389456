/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import "./FilterData.scss";
import { ArrowDown, ArrowUp } from "react-feather";
import InputSelect from "components/atoms/InputSelect";
import InputRadio from "components/atoms/InputRadio";
import Button from "components/atoms/Button";
import InputDate from "components/atoms/InputDate";
import moment from "moment";
import Wilayah from "services/Wilayah";
import Analysis from "services/Analysis";
import Fasyankes from "services/Fasyankes";
import { Source, UserLevel, indicatorAnalysisOptions } from "utils/constant";
import { getCurrentUserFromLocalStorage, monthConverter } from "utils/common";
import Chart from "./Chart";
import { HashLoader } from "react-spinners";
import MyMap from "./Map";
import { toast } from "react-toastify";

const WilayahService = new Wilayah();
const FasyankesService = new Fasyankes();
const AnalysisService = new Analysis();

const initialPeriodDate = {
  year: null,
  month: null,
  week: null,
  startDate: null,
  endDate: null,
};

const initialDate = {
  year: "",
  month: "",
  week: "",
  dateFrom: "",
  dateTo: "",
};

const initialDomicile = {
  provincies: [],
  districts: [],
  fasyankes: [],
};

const FilterDataComponent = () => {
  const currentUser = getCurrentUserFromLocalStorage();

  const initialDomicileSelected = {
    provincies: currentUser?.province_id,
    districts: currentUser?.district_id,
    fasyankes: currentUser?.fasyankes_id,
  };

  const [chartData, setChartData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [showFilter, setShowFilter] = useState(true);
  const [showChart, setShowChart] = useState(false);
  const [dateString, setDateString] = useState("");
  const [date, setDate] = useState(initialDate);
  const [domicile, setDomicile] = useState(initialDomicile);
  const [domicileSelected, setDomicileSelected] = useState(
    initialDomicileSelected
  );
  const [selectPeriode, setSelectPeriode] = useState("");
  const [selectLevel, setSelectedLevel] = useState(currentUser.level);
  const [levelData, setLevelData] = useState([]);
  const [selectedIndikator, setSelectedIndikator] = useState("");

  const [periodDate, setPeriodDate] = useState(initialPeriodDate);
  const [currentDomicileSelected, setCurrentDomicileSelected] = useState({});
  const [currentDomicile, setCurrentDomicile] = useState("");

  const [levelIdSelected, setLevelIdSelected] = useState("Indonesia");

  // total denum proposi plasmodium
  const [totalData, setTotalData] = useState(0);

  // selected wilayah
  const getWeekNumber = (date) => {
    const d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const generateWeeks = (year) => {
    const weeks = [];
    const startDate = new Date(year, 0, 1);
    const endDate = new Date(year, 11, 31);
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const weekStartDate = new Date(currentDate);
      const weekEndDate = new Date(currentDate);
      weekEndDate.setDate(currentDate.getDate() + 6);

      const weekLabel = `Minggu ${getWeekNumber(currentDate)}`;
      const weekRange = `${formatDate(weekStartDate)} - ${formatDate(
        weekEndDate
      )}`;

      weeks.push({ label: weekLabel, value: weekRange });

      currentDate.setDate(currentDate.getDate() + 7);
    }

    return weeks;
  };

  const renderPeriodeFilter = () => {
    if (selectPeriode === "Tahunan") {
      if (
        selectedIndikator === "indikator_3" ||
        selectedIndikator === "indikator_16" ||
        selectedIndikator === "indikator_25" ||
        selectedIndikator === "indikator_6"
      ) {
        return (
          <div className="filter-condition">
            <InputDate
              label={"Dari"}
              placeholder={"Pilih Tahun"}
              dateFormat={"YYYY"}
              onChange={(e) => {
                setDate({
                  ...date,
                  year: moment(e).format("YYYY"),
                  dateFrom: moment(e).format("YYYY-MM-DD"),
                });
                setPeriodDate({ ...periodDate, year: e, startDate: e });
              }}
              value={periodDate.startDate}
            />
            <InputDate
              label={"Sampai"}
              placeholder={"Pilih Tahun"}
              dateFormat={"YYYY"}
              onChange={(e) => {
                setDate({
                  ...date,
                  year: moment(e).format("YYYY"),
                  dateTo: moment(e).format("YYYY-MM-DD"),
                });
                setPeriodDate({ ...periodDate, year: e, endDate: e });
              }}
              value={periodDate.endDate}
            />
          </div>
        );
      }
      return (
        <InputDate
          placeholder={"Pilih Tahun"}
          dateFormat={"YYYY"}
          onChange={(e) => {
            setDate({ ...date, year: moment(e).format("YYYY") });
            setPeriodDate({ year: e });
          }}
          value={periodDate.year}
        />
      );
    } else if (selectPeriode === "Bulanan") {
      if (
        selectedIndikator === "indikator_12" ||
        selectedIndikator === "indikator_13" ||
        selectedIndikator === "indikator_15" ||
        selectedIndikator === "indikator_17" ||
        selectedIndikator === "indikator_18" ||
        selectedIndikator === "indikator_21" ||
        selectedIndikator === "indikator_22"
      ) {
        return (
          <div className="filter-condition">
            <InputDate
              label={"Dari"}
              placeholder={"Pilih Tanggal"}
              dateFormat={"YYYY-MM-DD"}
              onChange={(e) => {
                setDate({
                  ...date,
                  year: moment(e).format("YYYY"),
                  dateFrom: moment(e).format("YYYY-MM"),
                });
                setPeriodDate({ ...periodDate, year: e, startDate: e });
              }}
              value={periodDate.month || periodDate.startDate}
            />
            <InputDate
              label={"Sampai"}
              placeholder={"Pilih Tanggal"}
              dateFormat={"YYYY-MM-DD"}
              onChange={(e) => {
                setDate({
                  ...date,
                  month: moment(e).format("MM"),
                  dateTo: moment(e).format("YYYY-MM"),
                });
                setPeriodDate({ ...periodDate, year: e, endDate: e });
              }}
              value={periodDate.month || periodDate.endDate}
            />
          </div>
        );
      } else if (
        selectedIndikator === "indikator_3" ||
        selectedIndikator === "indikator_16" ||
        selectedIndikator === "indikator_25" ||
        selectedIndikator === "indikator_6"
      ) {
        return (
          <div className="filter-condition">
            <InputDate
              label="Dari"
              placeholder={"Pilih Tahun"}
              dateFormat={"YYYY-MM"}
              onChange={(e) => {
                setDate({
                  ...date,
                  year: moment(e).format("YYYY"),
                  dateFrom: moment(e).format("YYYY-MM-DD"),
                });
                setPeriodDate({ ...periodDate, year: e, startDate: e });
              }}
              value={periodDate.startDate}
            />
            <InputDate
              label="Sampai"
              placeholder={"Pilih Bulan"}
              dateFormat={"YYYY-MM"}
              onChange={(e) => {
                setDate({
                  ...date,
                  month: moment(e).format("MM"),
                  dateTo: moment(e).format("YYYY-MM-DD"),
                });
                setPeriodDate({ ...periodDate, month: e, endDate: e });
              }}
              value={periodDate.endDate}
            />
          </div>
        );
      }
      return (
        <div className="filter-condition">
          <InputDate
            placeholder={"Pilih Tahun"}
            dateFormat={"YYYY"}
            onChange={(e) => {
              setDate({
                ...date,
                year: moment(e).format("YYYY"),
                dateFrom: moment(e).format("YYYY-MM-DD"),
              });
              setPeriodDate({ ...periodDate, year: e, startDate: e });
            }}
            value={periodDate.year || periodDate.startDate}
          />
          <InputDate
            placeholder={"Pilih Bulan"}
            dateFormat={"MM"}
            onChange={(e) => {
              setDate({
                ...date,
                month: moment(e).format("MM"),
                dateTo: moment(e).format("YYYY-MM-DD"),
              });
              setPeriodDate({ ...periodDate, month: e, endDate: e });
            }}
            value={periodDate.month || periodDate.endDate}
          />
        </div>
      );
    } else if (selectPeriode === "Mingguan") {
      if (
        selectedIndikator === "indikator_3" ||
        selectedIndikator === "indikator_16" ||
        selectedIndikator === "indikator_25" ||
        selectedIndikator === "indikator_6"
      ) {
        const weeks = generateWeeks(date.year);
        return (
          <div>
            <div className="filter-condition">
              <InputDate
                label="Dari"
                placeholder={"Pilih Tahun"}
                dateFormat={"YYYY"}
                onChange={(e) => {
                  setDate({
                    ...date,
                    year: moment(e).format("YYYY"),
                    dateFrom: moment(e).format("YYYY-MM-DD"),
                  });
                  setPeriodDate({ ...periodDate, year: e, startDate: e });
                }}
                value={periodDate.startDate}
              />
              <InputSelect
                label={"Minggu ke-"}
                placeholder={"Pilih Minggu ke-"}
                data={weeks.map((item) => ({
                  label: `${item.label} (${item.value})`,
                  value:
                    item.label?.split(" ")[1] < 10
                      ? `0${item.label?.split(" ")[1]}`
                      : item.label?.split(" ")[1],
                }))}
                onChange={(e) => {
                  const selectedValue = e ? e.value : "";
                  setDate({ ...date, w1: selectedValue });
                }}
                value={
                  date.w1
                    ? {
                        label: date.w1 || "",
                        value: date.w1,
                      }
                    : null
                }
              />
            </div>
            <div className="filter-condition">
              <InputDate
                label="Sampai"
                placeholder={"Pilih Tahun"}
                dateFormat={"YYYY"}
                onChange={(e) => {
                  setDate({
                    ...date,
                    year: moment(e).format("YYYY"),
                    dateTo: moment(e).format("YYYY-MM-DD"),
                  });
                  setPeriodDate({ ...periodDate, year: e, endDate: e });
                }}
                value={periodDate.endDate}
              />
              <InputSelect
                label={"Minggu ke-"}
                placeholder={"Pilih Minggu ke-"}
                data={weeks.map((item) => ({
                  label: `${item.label} (${item.value})`,
                  value:
                    item.label?.split(" ")[1] < 10
                      ? `0${item.label?.split(" ")[1]}`
                      : item.label?.split(" ")[1],
                }))}
                onChange={(e) => {
                  const selectedValue = e ? e.value : "";
                  setDate({ ...date, w2: selectedValue });
                }}
                value={
                  date.w2
                    ? {
                        label: date.w2 || "",
                        value: date.w2,
                      }
                    : null
                }
              />
            </div>
          </div>
        );
      } else {
        const weeks = generateWeeks(date.year);
        if (date.year) {
          return (
            <div className="filter-condition">
              <InputDate
                placeholder={"Pilih Tahun"}
                dateFormat={"YYYY"}
                value={date.year}
                onChange={(e) => {
                  setDate({ ...date, year: moment(e).format("YYYY") });
                  setPeriodDate({ year: e });
                }}
              />
              <InputSelect
                placeholder={"Pilih Minggu"}
                data={weeks.map((week) => ({
                  label: `${week.label} ${week.value}`,
                  value: week.value,
                }))}
                onChange={(e) => {
                  setDate({ ...date, week: e.value });
                  setPeriodDate({ week: e.value });
                }}
              />
            </div>
          );
        } else {
          return (
            <div className="filter-condition">
              <InputDate
                placeholder={"Pilih Tahun"}
                dateFormat={"YYYY"}
                value={date.year}
                onChange={(e) => {
                  setDate({ ...date, year: moment(e).format("YYYY") });
                  setPeriodDate({ year: e });
                }}
              />
            </div>
          );
        }
      }
    } else if (selectPeriode === "Harian") {
      return (
        <div className="filter-condition">
          <InputDate
            label={"Dari"}
            placeholder={"Pilih Tanggal"}
            dateFormat={"YYYY-MM-DD"}
            onChange={(e) => {
              setDate({
                ...date,
                year: moment(e).format("YYYY"),
                dateFrom: moment(e).format("YYYY-MM-DD"),
              });
              setPeriodDate({ year: e, startDate: e });
            }}
            value={periodDate.year || periodDate.startDate}
          />
          <InputDate
            label={"Sampai"}
            placeholder={"Pilih Tanggal"}
            dateFormat={"YYYY-MM-DD"}
            onChange={(e) => {
              setDate({
                ...date,
                month: moment(e).format("MM"),
                dateTo: moment(e).format("YYYY-MM-DD"),
              });
              setPeriodDate({ month: e, endDate: e });
            }}
            value={periodDate.month || periodDate.endDate}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderLevelFilter = () => {
    if (selectLevel === UserLevel.Province) {
      return (
        <div style={{ display: "flex", flexDirection: "row", gap: "1.2rem" }}>
          <div style={{ flex: "1" }}>
            <InputSelect
              placeholder={"Pilih Provinsi"}
              data={domicile.provincies}
              onChange={(e) => {
                const value = e ? e.value : null;
                setDomicileSelected({
                  ...domicileSelected,
                  provincies: value,
                  districts: null,
                  fasyankes: null,
                });
              }}
              value={
                domicileSelected.provincies
                  ? domicile.provincies.find(
                      (f) => f.value === domicileSelected.provincies
                    )
                  : domicileSelected.provincies
              }
              isDisabled={
                currentUser.level === UserLevel.National ? false : true
              }
            />
          </div>
        </div>
      );
    } else if (selectLevel === UserLevel.District) {
      return (
        <div style={{ display: "flex", flexDirection: "row", gap: "1.2rem" }}>
          <div style={{ flex: "1" }}>
            <InputSelect
              placeholder={"Pilih Provinsi"}
              data={domicile.provincies}
              onChange={(e) => {
                const value = e ? e.value : null;
                setDomicileSelected({
                  ...domicileSelected,
                  provincies: value,
                  districts: null,
                  fasyankes: null,
                });
              }}
              value={
                domicileSelected.provincies
                  ? domicile.provincies.find(
                      (f) => f.value === domicileSelected.provincies
                    )
                  : domicileSelected.provincies
              }
              isDisabled={
                currentUser.level === UserLevel.National ? false : true
              }
            />
          </div>
          <div style={{ flex: "1" }}>
            <InputSelect
              placeholder={"Pilih Kab/Kota"}
              data={domicile.districts}
              onChange={(e) => {
                const value = e ? e.value : null;
                setDomicileSelected({
                  ...domicileSelected,
                  districts: value,
                  fasyankes: null,
                });
              }}
              value={
                domicileSelected.districts
                  ? domicile.districts.find(
                      (f) => f.value === domicileSelected.districts
                    )
                  : domicileSelected.districts
              }
              isDisabled={
                currentUser.level === UserLevel.National ||
                currentUser.level === UserLevel.Province
                  ? false
                  : true
              }
            />
          </div>
        </div>
      );
    } else if (selectLevel === UserLevel.Fasyankes) {
      return (
        <div style={{ display: "flex", flexDirection: "row", gap: "1.2rem" }}>
          <div style={{ flex: "1" }}>
            <InputSelect
              placeholder={"Pilih Provinsi"}
              data={domicile.provincies}
              onChange={(e) => {
                const value = e ? e.value : null;
                setDomicileSelected({
                  ...domicileSelected,
                  provincies: value,
                  districts: null,
                  fasyankes: null,
                });
              }}
              value={
                domicileSelected.provincies
                  ? domicile.provincies.find(
                      (f) => f.value === domicileSelected.provincies
                    )
                  : domicileSelected.provincies
              }
              isDisabled={
                currentUser?.level === UserLevel.National ? false : true
              }
            />
          </div>
          <div style={{ flex: "1" }}>
            <InputSelect
              placeholder={"Pilih Kab/Kota"}
              data={domicile.districts}
              onChange={(e) => {
                const value = e ? e.value : null;
                setDomicileSelected({
                  ...domicileSelected,
                  districts: value,
                  fasyankes: null,
                });
              }}
              value={
                domicileSelected.districts
                  ? domicile.districts.find(
                      (f) => f.value === domicileSelected.districts
                    )
                  : domicileSelected.districts
              }
              isDisabled={
                currentUser?.level === UserLevel.National ||
                currentUser?.level === UserLevel.Province
                  ? false
                  : true
              }
            />
          </div>
          <div style={{ flex: "1" }}>
            <InputSelect
              placeholder={"Pilih Faskes"}
              data={domicile.fasyankes}
              onChange={(e) => {
                const value = e ? e.value : null;
                setDomicileSelected({ ...domicileSelected, fasyankes: value });
              }}
              value={
                domicileSelected.fasyankes
                  ? domicile.fasyankes.find(
                      (f) => f.value === domicileSelected.fasyankes
                    )
                  : domicileSelected.fasyankes
              }
              isDisabled={
                currentUser?.level === UserLevel.National ||
                currentUser?.level === UserLevel.Province ||
                currentUser?.level === UserLevel.District
                  ? false
                  : true
              }
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleApplyFilter = async () => {
    setShowChart(true);
    setLoading(true);
    if (currentUser.level && selectLevel) {
      let dateSelected = "";
      let levelIdSelected = "";
      if (selectPeriode === "Tahunan") {
        if (
          selectedIndikator === "indikator_3" ||
          selectedIndikator === "indikator_16" ||
          selectedIndikator === "indikator_25" ||
          selectedIndikator === "indikator_6"
        ) {
          dateSelected = `${date.dateFrom}|${date.dateTo}`;
        } else {
          dateSelected = date.year;
        }
      } else if (selectPeriode === "Bulanan") {
        if (
          selectedIndikator === "indikator_3" ||
          selectedIndikator === "indikator_12" ||
          selectedIndikator === "indikator_13" ||
          selectedIndikator === "indikator_15" ||
          selectedIndikator === "indikator_16" ||
          selectedIndikator === "indikator_17" ||
          selectedIndikator === "indikator_18" ||
          selectedIndikator === "indikator_21" ||
          selectedIndikator === "indikator_22" ||
          selectedIndikator === "indikator_25" ||
          selectedIndikator === "indikator_6"
        ) {
          dateSelected = `${date.dateFrom}|${date.dateTo}`;
        } else {
          dateSelected = `${date.year}-${date.month}`;
        }
      } else if (selectPeriode === "Mingguan") {
        if (
          selectedIndikator === "indikator_11" ||
          selectedIndikator === "indikator_19" ||
          selectedIndikator === "indikator_20"
        ) {
          dateSelected = `${date.week}`;
        } else if (
          selectedIndikator === "indikator_3" ||
          selectedIndikator === "indikator_16" ||
          selectedIndikator === "indikator_25" ||
          selectedIndikator === "indikator_6"
        ) {
          dateSelected = `${date.dateFrom};${date.w1}|${date.dateTo};${date.w2}`;
        } else {
          dateSelected = `${date.year}-${date.week}`;
        }
      } else if (selectPeriode === "Harian") {
        dateSelected = `${date.dateFrom}|${date.dateTo}`;
      }
      if (selectLevel === UserLevel.Fasyankes) {
        levelIdSelected = domicileSelected.fasyankes;
      } else if (selectLevel === UserLevel.District) {
        levelIdSelected = domicileSelected.districts;
      } else if (selectLevel === UserLevel.Province) {
        levelIdSelected = domicileSelected.provincies;
      } else if (selectLevel === UserLevel.National) {
        levelIdSelected = "1";
      }

      const levelIdValue = levelIdSelected?.includes("|")
        ? levelIdSelected?.split("|")[0]
        : levelIdSelected;

      setLevelIdSelected(
        levelIdSelected == "1"
          ? "INDONESIA"
          : levelIdSelected?.includes("|")
          ? levelIdSelected?.split("|")[1]
          : levelIdSelected
      );

      if (
        (selectedIndikator == "indikator_27" || dateSelected) &&
        selectedIndikator
      ) {
        await getAnalysis(
          dateSelected,
          selectLevel.toLowerCase(),
          levelIdValue,
          selectedIndikator
        );
      }
      setDateString(dateSelected);
      if (selectLevel === "National") {
        setCurrentDomicile("Nasional");
      } else if (selectLevel === "Province") {
        setCurrentDomicile(domicileSelected.provincies?.split("|")[1] || "");
      } else if (selectLevel === "District") {
        setCurrentDomicile(
          `${domicileSelected.provincies?.split("|")[1] || ""} - ${
            domicileSelected.districts?.split("|")[1] || ""
          }`
        );
      } else {
        setCurrentDomicile(
          `${domicileSelected.provincies?.split("|")[1] || ""} - ${
            domicileSelected.districts?.split("|")[1] || ""
          } - ${domicileSelected.fasyankes?.split("|")[1] || ""}`
        );
      }
    }
    setLoading(false);
  };

  const getAllProvincies = async () => {
    try {
      const response = await WilayahService.getAll(Source.PROVINCE, {
        page: 1,
        limit: 999,
      });

      setDomicile((prevState) => ({
        ...prevState,
        provincies: response.province.map((province) => {
          return {
            label: province.name,
            value: `${province.id}|${province.name}`,
          };
        }),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getAllDistrictsByProvince = async (provinceId) => {
    try {
      const response = await WilayahService.getAll(Source.DISTRICT, {
        page: 1,
        limit: 9999,
        searchBy: `province_id`,
        searchValue: provinceId?.split("|")[0],
      });

      const data = response.district.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setDomicile((prevState) => ({
        ...prevState,
        districts: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getAllFasyankesByDistrict = async (districtId) => {
    try {
      const response = await FasyankesService.getAll(Source.FASYANKES, {
        page: 1,
        limit: 9999,
        searchBy: `district_id`,
        searchValue: districtId?.split("|")[0],
        excludePkm: false,
      });

      const data = response.fasyankes.map((item) => ({
        label: item.name,
        value: `${item.id}|${item.name}`,
      }));

      setDomicile((prevState) => ({
        ...prevState,
        fasyankes: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getAnalysis = async (date, level, level_id, indikator) => {
    try {
      let response = null;
      if (indikator === "indikator_1") {
        response = await AnalysisService.getReportCompleteness({
          date,
          level,
          level_id,
        });
      } else if (indikator === "indikator_2") {
        response = await AnalysisService.getPositiveCase({
          date,
          level,
          level_id,
        });
      } else if (indikator === "indikator_3") {
        const finalDate = date?.split("|");
        response = await AnalysisService.getDeathTrends({
          level,
          level_id,
          period:
            selectPeriode === "Tahunan"
              ? "yearly"
              : selectPeriode === "Bulanan"
              ? "monthly"
              : selectPeriode === "Mingguan"
              ? "weekly"
              : "daily",
          dateStart:
            selectPeriode === "Tahunan"
              ? new Date(finalDate[0]).getFullYear().toString()
              : selectPeriode === "Bulanan"
              ? `${finalDate[0]?.split("-")[0]}-${finalDate[0]?.split("-")[1]}`
              : selectPeriode === "Mingguan"
              ? `${finalDate[0]?.split("-")[0]}-${finalDate[0]?.split(";")[1]}`
              : finalDate[0],
          dateEnd:
            selectPeriode === "Tahunan"
              ? new Date(finalDate[1]).getFullYear().toString()
              : selectPeriode === "Bulanan"
              ? `${finalDate[1]?.split("-")[0]}-${finalDate[1]?.split("-")[1]}`
              : selectPeriode === "Mingguan"
              ? `${finalDate[1]?.split("-")[0]}-${finalDate[1]?.split(";")[1]}`
              : finalDate[1],
        });
      } else if (indikator === "indikator_4") {
        response = await AnalysisService.getLaboratoryConfirmedSuspect({
          date,
          level,
          level_id,
        });
      } else if (indikator === "indikator_5") {
        response = await AnalysisService.getABER({
          date,
          level,
          level_id,
        });
      } else if (indikator === "indikator_6") {
        const finalDate = date?.split("|");

        response = await AnalysisService.getValidatedIndegenousCase({
          level,
          level_id,
          period:
            selectPeriode === "Tahunan"
              ? "yearly"
              : selectPeriode === "Bulanan"
              ? "monthly"
              : selectPeriode === "Mingguan"
              ? "weekly"
              : "daily",
          dateStart:
            selectPeriode === "Tahunan"
              ? new Date(finalDate[0]).getFullYear().toString()
              : selectPeriode === "Bulanan"
              ? `${finalDate[0]?.split("-")[0]}-${finalDate[0]?.split("-")[1]}`
              : selectPeriode === "Mingguan"
              ? `${finalDate[0]?.split("-")[0]}-${finalDate[0]?.split(";")[1]}`
              : finalDate[0],
          dateEnd:
            selectPeriode === "Tahunan"
              ? new Date(finalDate[1]).getFullYear().toString()
              : selectPeriode === "Bulanan"
              ? `${finalDate[1]?.split("-")[0]}-${finalDate[1]?.split("-")[1]}`
              : selectPeriode === "Mingguan"
              ? `${finalDate[1]?.split("-")[0]}-${finalDate[1]?.split(";")[1]}`
              : finalDate[1],
        });
      } else if (indikator === "indikator_7") {
        response = await AnalysisService.getInspection({
          date,
          level,
          level_id,
        });
      } else if (indikator === "indikator_8") {
        response = await AnalysisService.getApiMoreOne({
          date,
          level,
          level_id,
        });
      } else if (indikator === "indikator_9") {
        response = await AnalysisService.getApiLessOne({
          date,
          level,
          level_id,
        });
      } else if (indikator === "indikator_11") {
        const finalDate = date?.split(
          selectPeriode === "Mingguan" ? " - " : "|"
        );
        response = await AnalysisService.getStandardMedication({
          dateStart:
            selectPeriode === "Tahunan" || selectPeriode === "Bulanan"
              ? date
              : finalDate[0],
          dateEnd:
            selectPeriode === "Tahunan" || selectPeriode === "Bulanan"
              ? date
              : finalDate[1],
          level,
          level_id,
        });
      } else if (indikator === "indikator_10") {
        response = await AnalysisService.getPositivityRate({
          level,
          level_id,
          period:
            selectPeriode === "Tahunan"
              ? "yearly"
              : selectPeriode === "Bulanan" && "monthly",
          year:
            selectPeriode === "Tahunan"
              ? date
              : selectPeriode === "Bulanan"
              ? date?.split("-")[0]
              : null,
          month: selectPeriode === "Bulanan" ? date?.split("-")[1] : null,
        });
      } else if (indikator === "indikator_12") {
        response = await AnalysisService.getProporsiJenisPlasmodiun({
          dateStart: date?.split("|")[0],
          dateEnd: date?.split("|")[1],
          level,
          level_id,
        });
      } else if (indikator === "indikator_13") {
        response = await AnalysisService.getKasusIndigenous({
          dateStart: date?.split("|")[0],
          dateEnd: date?.split("|")[1],
          level,
          level_id,
        });
      } else if (indikator === "indikator_15") {
        response = await AnalysisService.getPosCasePE({
          dateStart: date?.split("|")[0],
          dateEnd: date?.split("|")[1],
          level,
          level_id,
        });
      } else if (indikator === "indikator_21") {
        response = await AnalysisService.getProportionPositiveCaseOperator({
          dateStart: selectPeriode === "Tahunan" ? date : date?.split("|")[0],
          dateEnd: selectPeriode === "Tahunan" ? date : date?.split("|")[1],
          level,
          level_id,
        });
      } else if (indikator === "indikator_22") {
        response = await AnalysisService.getProportionCaseClassification({
          dateStart: selectPeriode === "Tahunan" ? date : date?.split("|")[0],
          dateEnd: selectPeriode === "Tahunan" ? date : date?.split("|")[1],
          level,
          level_id,
        });
      } else if (indikator === "indikator_17") {
        response = await AnalysisService.getProportionDiscoveryActivity({
          dateStart: selectPeriode === "Tahunan" ? date : date?.split("|")[0],
          dateEnd: selectPeriode === "Tahunan" ? date : date?.split("|")[1],
          level,
          level_id,
        });
      } else if (indikator === "indikator_18") {
        response = await AnalysisService.getProportionPositiveCaseOwnership({
          dateStart: selectPeriode === "Tahunan" ? date : date?.split("|")[0],
          dateEnd: selectPeriode === "Tahunan" ? date : date?.split("|")[1],
          level,
          level_id,
        });
      } else if (indikator === "indikator_19") {
        const finalDate = date?.split(
          selectPeriode === "Mingguan" ? " - " : "|"
        );
        response = await AnalysisService.getIndicatorLogisticOffStock({
          dateStart:
            selectPeriode === "Tahunan" || selectPeriode === "Bulanan"
              ? date
              : finalDate[0],
          dateEnd:
            selectPeriode === "Tahunan" || selectPeriode === "Bulanan"
              ? date
              : finalDate[1],
          level,
          level_id,
        });
      } else if (indikator === "indikator_20") {
        const finalDate = date?.split(
          selectPeriode === "Mingguan" ? " - " : "|"
        );
        response = await AnalysisService.getDeaths({
          dateStart:
            selectPeriode === "Tahunan" || selectPeriode === "Bulanan"
              ? date
              : finalDate[0],
          dateEnd:
            selectPeriode === "Tahunan" || selectPeriode === "Bulanan"
              ? date
              : finalDate[1],
          level,
          level_id,
        });
      } else if (indikator === "indikator_14") {
        response = await AnalysisService.getReportLogisticCompleteness({
          date,
          level,
          level_id,
        });
      } else if (indikator === "indikator_23") {
        response = await AnalysisService.getCaderPerformance({
          date,
          level,
          level_id,
          type: "kunjungan",
        });
      } else if (indikator === "indikator_23A") {
        response = await AnalysisService.getCaderPerformance({
          date,
          level,
          level_id,
          type: "pemeriksaan",
        });
      } else if (indikator === "indikator_24") {
        response = await AnalysisService.getAnnualParasiteInsiden({
          date,
          level,
          level_id,
        });
      } else if (selectedIndikator === "indikator_16") {
        const finalDate = date?.split("|");
        response = await AnalysisService.getSKDMalariaTrends({
          level,
          level_id,
          period:
            selectPeriode === "Tahunan"
              ? "yearly"
              : selectPeriode === "Bulanan"
              ? "monthly"
              : selectPeriode === "Mingguan"
              ? "weekly"
              : "daily",
          dateStart:
            selectPeriode === "Tahunan"
              ? new Date(finalDate[0]).getFullYear().toString()
              : selectPeriode === "Bulanan"
              ? `${finalDate[0]?.split("-")[0]}-${finalDate[0]?.split("-")[1]}`
              : selectPeriode === "Mingguan"
              ? `${finalDate[0]?.split("-")[0]}-${finalDate[0]?.split(";")[1]}`
              : finalDate[0],
          dateEnd:
            selectPeriode === "Tahunan"
              ? new Date(finalDate[1]).getFullYear().toString()
              : selectPeriode === "Bulanan"
              ? `${finalDate[1]?.split("-")[0]}-${finalDate[1]?.split("-")[1]}`
              : selectPeriode === "Mingguan"
              ? `${finalDate[1]?.split("-")[0]}-${finalDate[1]?.split(";")[1]}`
              : finalDate[1],
        });
      } else if (selectedIndikator === "indikator_25") {
        const finalDate = date?.split("|");
        response = await AnalysisService.getKetepatanPE({
          level,
          level_id,
          period:
            selectPeriode === "Tahunan"
              ? "yearly"
              : selectPeriode === "Bulanan"
              ? "monthly"
              : selectPeriode === "Mingguan"
              ? "weekly"
              : "daily",
          dateStart:
            selectPeriode === "Tahunan"
              ? new Date(finalDate[0]).getFullYear().toString()
              : selectPeriode === "Bulanan"
              ? `${finalDate[0]?.split("-")[0]}-${finalDate[0]?.split("-")[1]}`
              : selectPeriode === "Mingguan"
              ? `${finalDate[0]?.split("-")[0]}-${finalDate[0]?.split(";")[1]}`
              : finalDate[0],
          dateEnd:
            selectPeriode === "Tahunan"
              ? new Date(finalDate[1]).getFullYear().toString()
              : selectPeriode === "Bulanan"
              ? `${finalDate[1]?.split("-")[0]}-${finalDate[1]?.split("-")[1]}`
              : selectPeriode === "Mingguan"
              ? `${finalDate[1]?.split("-")[0]}-${finalDate[1]?.split(";")[1]}`
              : finalDate[1],
        });
      } else if (selectedIndikator === "indikator_26") {
        response = await AnalysisService.getPetaEndemis({
          year: new Date(date).getFullYear(),
          level_id,
          level,
        });
      } else if (selectedIndikator === "indikator_28") {
        response = await AnalysisService.getPetaFokus({
          year: new Date(date).getFullYear(),
          level_id,
          level,
        });
      } else if (selectedIndikator === "indikator_29") {
        response = await AnalysisService.getPetaReseptifitas({
          year: new Date(date).getFullYear(),
          level_id,
          level,
        });
      } else if (selectedIndikator === "indikator_27") {
        response = await AnalysisService.getProporsiJenisMMP({
          level,
          level_id,
        });
      }

      if (response) {
        if (
          indikator === "indikator_17" ||
          indikator === "indikator_18" ||
          indikator === "indikator_21" ||
          indikator === "indikator_22" ||
          indikator === "indikator_23" ||
          indikator === "indikator_23A" ||
          indikator === "indikator_27"
        ) {
          setChartData(response);
        } else if (
          indikator === "indikator_5" ||
          indikator === "indikator_24" ||
          indikator === "indikator_10"
        ) {
          setChartData(response.result);
        } else {
          setChartData(response.data);
          setTotalData(response.total);
        }
      }
    } catch (error) {
      toast.error(`Gagal: ${error.response.data.message}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllProvincies();
  }, []);

  useEffect(() => {
    if (domicileSelected.provincies) {
      getAllDistrictsByProvince(domicileSelected.provincies);
    }
  }, [domicileSelected.provincies]);

  useEffect(() => {
    if (domicileSelected.districts) {
      getAllFasyankesByDistrict(domicileSelected.districts);
    }
  }, [domicileSelected.districts]);

  useEffect(() => {
    switch (currentUser.level) {
      case UserLevel.Fasyankes:
        setLevelData([{ label: "Fasyankes", value: UserLevel.Fasyankes }]);
        break;
      case UserLevel.District:
        setLevelData([
          { label: "Kabupaten", value: UserLevel.District },
          { label: "Fasyankes", value: UserLevel.Fasyankes },
        ]);
        break;
      case UserLevel.Province:
        setLevelData([
          { label: "Provinsi", value: UserLevel.Province },
          { label: "Kabupaten", value: UserLevel.District },
          { label: "Fasyankes", value: UserLevel.Fasyankes },
        ]);
        break;

      default:
        setLevelData([
          { label: "Nasional", value: UserLevel.National },
          { label: "Provinsi", value: UserLevel.Province },
          { label: "Kabupaten", value: UserLevel.District },
          { label: "Fasyankes", value: UserLevel.Fasyankes },
        ]);
        break;
    }
  }, []);

  return (
    <>
      <div className="filter">
        <div className="filter-header">
          <p>FILTER</p>
          <button
            className="btn_toggle_filter"
            onClick={() => setShowFilter(!showFilter)}
          >
            {showFilter ? <ArrowUp size={18} /> : <ArrowDown size={18} />}
          </button>
        </div>
        {showFilter && (
          <div className="filter-body">
            <div className="filter-item">
              <div className="filter-label">
                <p>Indikator</p>
              </div>
              <div className="filter-field">
                <InputSelect
                  placeholder={"Pilih Indikator"}
                  data={indicatorAnalysisOptions}
                  onChange={(e) => {
                    const selectedValue = e ? e.value : "";
                    setSelectedIndikator(selectedValue);
                    setShowChart(false);
                  }}
                />
              </div>
            </div>
            {selectedIndikator !== "indikator_27" && (
              <div className="filter-item">
                <div className="filter-label">
                  <p>Periode Laporan</p>
                </div>
                <div className="filter-field">
                  <InputRadio
                    options={
                      selectedIndikator === "indikator_1" ||
                      selectedIndikator === "indikator_2" ||
                      selectedIndikator === "indikator_4" ||
                      selectedIndikator === "indikator_5" ||
                      selectedIndikator === "indikator_7" ||
                      selectedIndikator === "indikator_8" ||
                      selectedIndikator === "indikator_9" ||
                      selectedIndikator === "indikator_10" ||
                      selectedIndikator === "indikator_14" ||
                      selectedIndikator === "indikator_17" ||
                      selectedIndikator === "indikator_18" ||
                      selectedIndikator === "indikator_19" ||
                      selectedIndikator === "indikator_21" ||
                      selectedIndikator === "indikator_22" ||
                      selectedIndikator === "indikator_23" ||
                      selectedIndikator === "indikator_23A" ||
                      selectedIndikator === "indikator_24"
                        ? [
                            { label: "Tahunan", value: "Tahunan" },
                            { label: "Bulanan", value: "Bulanan" },
                          ]
                        : selectedIndikator === "indikator_3" ||
                          selectedIndikator === "indikator_6" ||
                          selectedIndikator === "indikator_11" ||
                          selectedIndikator === "indikator_16" ||
                          selectedIndikator === "indikator_20" ||
                          selectedIndikator === "indikator_25"
                        ? [
                            { label: "Tahunan", value: "Tahunan" },
                            { label: "Bulanan", value: "Bulanan" },
                            { label: "Mingguan", value: "Mingguan" },
                            { label: "Harian", value: "Harian" },
                          ]
                        : selectedIndikator === "indikator_12" ||
                          selectedIndikator === "indikator_13" ||
                          selectedIndikator === "indikator_15"
                        ? [{ label: "Bulanan", value: "Bulanan" }]
                        : selectedIndikator === "indikator_26" ||
                          selectedIndikator === "indikator_28" ||
                          selectedIndikator === "indikator_29"
                        ? [{ label: "Tahunan", value: "Tahunan" }]
                        : []
                    }
                    onChange={(e) => {
                      setSelectPeriode(e.target.value);
                      setDate(initialDate);
                      setPeriodDate(initialPeriodDate);
                    }}
                    selectedOption={selectPeriode}
                  />
                  <div className="advanced-filter">{renderPeriodeFilter()}</div>
                </div>
              </div>
            )}
            <div className="filter-item">
              <div className="filter-label">
                <p>Level</p>
              </div>
              <div className="filter-field">
                <InputRadio
                  options={
                    selectedIndikator === "indikator_26"
                      ? levelData.filter(
                          (r) =>
                            r.value === UserLevel.National ||
                            r.value === UserLevel.Province
                        )
                      : selectedIndikator === "indikator_28" ||
                        selectedIndikator === "indikator_29"
                      ? levelData.filter((r) => r.value !== UserLevel.National)
                      : levelData
                  }
                  onChange={(e) => {
                    setSelectedLevel(e.target.value);
                    setPeriodDate(initialPeriodDate);
                  }}
                  selectedOption={selectLevel}
                />
                <div className="advanced-filter">{renderLevelFilter()}</div>
              </div>
            </div>
            <div className="filter-action">
              <Button
                variant={"primary"}
                size={"normal"}
                onClick={() => handleApplyFilter()}
                isDisabled={
                  selectedIndikator !== "indikator_27" &&
                  !date.year &&
                  !date.week &&
                  !date.month &&
                  !date.dateFrom &&
                  !date.dateTo &&
                  !date.w1 &&
                  !date.w2
                }
              >
                Terapkan
              </Button>
            </div>
          </div>
        )}
      </div>
      {isLoading && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "5rem",
          }}
        >
          <HashLoader size={100} color="#18b3ab" />
        </div>
      )}
      {(selectedIndikator ||
        selectedIndikator === "indikator_27" ||
        dateString) &&
        selectLevel &&
        !isLoading && (
          <div className="chart-container">
            <div className="chart-header">
              {
                indicatorAnalysisOptions.find(
                  (r) => r.value === selectedIndikator
                ).label
              }
            </div>
            <div className="chart-body">
              <div className="data-information">
                <p>
                  <span className="title-label">Level:</span>
                  {currentDomicile}
                </p>
                <p>
                  <span className="title-label">Periode Laporan:</span>{" "}
                  {dateString.includes(";")
                    ? `${dateString
                        ?.split("|")[0]
                        ?.split(";")[0]
                        ?.substring(0, 4)}
                      Minggu ke-${
                        dateString?.split("|")[0]?.split(";")[1]
                      } Sampai
                      ${dateString
                        ?.split("|")[1]
                        ?.split(";")[0]
                        ?.substring(0, 4)} 
                      Minggu ke-${dateString?.split("|")[1]?.split(";")[1]}`
                    : dateString.includes("|")
                    ? `${dateString?.split("|")[0]} - ${
                        dateString?.split("|")[1]
                      }`
                    : dateString.includes("-")
                    ? `${dateString?.split("-")[0]} - ${monthConverter(
                        dateString?.split("-")[1]
                      )}`
                    : dateString}
                </p>
              </div>
              {showChart && (
                <>
                  {selectedIndikator === "indikator_1" ||
                  selectedIndikator === "indikator_2" ||
                  selectedIndikator === "indikator_4" ||
                  selectedIndikator === "indikator_5" ||
                  selectedIndikator === "indikator_6" ||
                  selectedIndikator === "indikator_7" ||
                  selectedIndikator === "indikator_8" ||
                  selectedIndikator === "indikator_9" ||
                  selectedIndikator === "indikator_10" ||
                  selectedIndikator === "indikator_11" ||
                  selectedIndikator === "indikator_13" ||
                  selectedIndikator === "indikator_14" ||
                  selectedIndikator === "indikator_15" ||
                  selectedIndikator === "indikator_19" ||
                  selectedIndikator === "indikator_20" ||
                  selectedIndikator === "indikator_24" ||
                  selectedIndikator === "indikator_25" ? (
                    <Chart
                      data={chartData}
                      type="column2d"
                      indicator={selectedIndikator}
                      period={selectPeriode}
                      level={selectLevel}
                      levelIdSelected={levelIdSelected}
                    />
                  ) : selectedIndikator === "indikator_13" ? (
                    <Chart
                      data={chartData}
                      type="line"
                      indicator={selectedIndikator}
                      period={selectPeriode}
                      level={selectLevel}
                      levelIdSelected={levelIdSelected}
                    />
                  ) : selectedIndikator === "indikator_3" ||
                    selectedIndikator === "indikator_16" ? (
                    <Chart
                      data={chartData}
                      type="mscombi2d"
                      indicator={selectedIndikator}
                      period={selectPeriode}
                      level={selectLevel}
                      levelIdSelected={levelIdSelected}
                    />
                  ) : (
                    (selectedIndikator === "indikator_12" ||
                      selectedIndikator === "indikator_17" ||
                      selectedIndikator === "indikator_18" ||
                      selectedIndikator === "indikator_21" ||
                      selectedIndikator === "indikator_22" ||
                      selectedIndikator === "indikator_23" ||
                      selectedIndikator === "indikator_23A" ||
                      selectedIndikator === "indikator_27") && (
                      <Chart
                        totalData={totalData}
                        data={chartData}
                        type="pie2d"
                        indicator={selectedIndikator}
                        period={selectPeriode}
                        level={selectLevel}
                        levelIdSelected={levelIdSelected}
                      />
                    )
                  )}
                  {(selectedIndikator === "indikator_26" ||
                    selectedIndikator === "indikator_28" ||
                    selectedIndikator === "indikator_29") && (
                    <div className="map">
                      <MyMap
                        geoJson={chartData}
                        indicator={selectedIndikator}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
    </>
  );
};

export default FilterDataComponent;
