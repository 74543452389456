import React, { Component } from "react";
import { ErrorMessage, PageMode, requestStatus } from "utils/constant";
import "react-datetime/css/react-datetime.css";
import Dropzone from "react-dropzone";
import LogisticRequestStock from "services/LogisticRequestStock";
import { Button, InputField, InputSelect } from "components";
import { connect } from "react-redux";
import InputDateTime from "components/InputDateTime";
import { AlertTriangle, MinusCircle, PlusCircle } from "react-feather";
import { toast } from "react-toastify";
import LogisticInputStock from "services/LogisticInputStock";
import moment from "moment/moment";

const LogisticReqStockService = new LogisticRequestStock();
const LogisticInputStockService = new LogisticInputStock();
const defaultAttr = (e) => {
  return {
    value: e ?? "",
    isValid: true,
    message: "",
  };
};
const logisticTypeAttr = () => {
  return {
    logistic_type: {
      value: "",
      isValid: false,
      message: "",
    },
    request_total: defaultAttr(""),
    unit: defaultAttr(""),
  };
};

class Form extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.onDrop = (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        if (fileRejections[0].errors[0].code === "file-too-large") {
          toast.error("Ukuran file tidak boleh lebih dari 300 Kb");
        } else if (fileRejections[0].errors[0].code === "file-invalid-type") {
          toast.error("File yang diupload harus berupa pdf");
        }
      }

      if (acceptedFiles.length > 0) {
        this.setState({
          files: acceptedFiles,
        });
      }
    };

    this.defaultValue = {
      level: defaultAttr("Fasyankes"),
      province_id: defaultAttr(this.props.user.province_id),
      district_id: defaultAttr(this.props.user.district_id),
      subdistrict_id: defaultAttr(this.props.user.subdistrict_id),
      fasyankes_id: defaultAttr(this.props.user.fasyankes_id),
      year_submission: defaultAttr(""),
      letter_date: defaultAttr(""),
      letter_number: defaultAttr(""),
      request_type: defaultAttr(""),
      logisticTypeDynamic: [
        {
          index: 0,
          ...logisticTypeAttr(),
        },
      ],
      listUnitOpt: [
        {
          index: 0,
          value: [],
        },
      ],
      files: [],
      showFileError: false,
      onEditFileName: "",

      isLoading: false,
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  componentDidMount() {
    this._isMounted = true;

    let obj = Object.assign({}, this.state);
    if (this.props.mode === PageMode.edit) {
      const { requestStock } = this.props;

      obj.province_id.value = requestStock.province_id;
      obj.district_id.value = requestStock.district_id;
      obj.subdistrict_id.value = requestStock.subdistrict_id;
      obj.fasyankes_id.value = requestStock.fasyankes_id;
      obj.year_submission.value = requestStock.year_submission;
      obj.letter_date.value = requestStock.letter_date;
      obj.letter_number.value = requestStock.letter_number;
      obj.request_type.value = requestStock.request_type;
      obj.logisticTypeDynamic[0].logistic_type.value =
        requestStock.logistic_type;
      obj.logisticTypeDynamic[0].request_total.value =
        requestStock.request_total;
      obj.logisticTypeDynamic[0].unit.value = requestStock.unit;
      obj.onEditFileName = requestStock.letter_upload;

      this.setState(obj);
      this.onLogisticChange(
        {
          target: { name: "logistic_type", value: requestStock.logistic_type },
        },
        { index: 0 }
      );
    } else {
      this.setState(obj);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, defaultAttr(value));

    if (name === "logistic_type") {
      this.setState({
        [name]: { value: value, isValid: true, message: "" },
      });
    }

    this.setState({ [name]: obj });
  };

  checkLogsticStatus = async (logsticType) => {
    let isValid = true;
    await LogisticInputStockService.getAll({
      page: 1,
      limit: 10,
      searchBy: "fasyankes_id,level,year,month,logistic_type",
      searchValue: `${this.state.fasyankes_id.value},Fasyankes,${moment(
        this.state.letter_date.value
      ).format("YYYY")},${moment(this.state.letter_date.value).format(
        "MM"
      )},${logsticType}`,
    })
      .then((data) => {
        if (data.total < 1) {
          toast.error(
            "Jenis logistik yang dipilih belum diinput pada menu input stok",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          isValid = false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return isValid;
  };

  onLogisticChange = async (e, item) => {
    const data = this.state.logisticTypeDynamic;
    data.map((x) => {
      if (x.index === item.index) {
        x[e.target.name] = defaultAttr(e.target.value);
      }
    });
    if (e.target.name === "logistic_type") {
      const valueOpt = e.target.value;
      const isValid = await this.checkLogsticStatus(valueOpt);
      data.map((x) => {
        if (x.index === item.index) {
          x[e.target.name].isValid = isValid;
        }
      });
      const dataOpt = this.state.listUnitOpt.map((x) => {
        if (x.index === item.index) {
          return {
            index: x.index,
            value: this.getUnitOpt(valueOpt),
          };
        }
        return x;
      });
      this.setState({ listUnitOpt: dataOpt });
    }
    this.setState({ logisticTypeDynamic: data });
  };

  onSave = async (e) => {
    e.preventDefault();

    if (this.onValidate()) {
      this.setState({
        isLoading: true,
      });

      const { logisticTypeDynamic, files } = this.state;
      let basePayload = {
        province_id: this.state.province_id.value,
        district_id: this.state.district_id.value,
        subdistrict_id: this.state.subdistrict_id.value,
        fasyankes_id: this.state.fasyankes_id.value,
        year_submission: this.state.year_submission.value,
        letter_date: this.state.letter_date.value,
        letter_number: this.state.letter_number.value,
        request_type: this.state.request_type.value,
        level: "Fasyankes",
      };

      switch (this.props.mode) {
        case PageMode.add:
          await LogisticReqStockService.add({
            ...basePayload,
            logistic_type: logisticTypeDynamic[0].logistic_type.value,
            request_total: logisticTypeDynamic[0].request_total.value,
            unit: logisticTypeDynamic[0].unit.value,
            file: files[0],
          })
            .then(async (res) => {
              this.setState({
                isLoading: false,
              });

              const letter_upload = res.data.data.letter_upload;
              for (let i = 1; i < logisticTypeDynamic.length; i++) {
                await LogisticReqStockService.add({
                  ...basePayload,
                  logistic_type: logisticTypeDynamic[i].logistic_type.value,
                  request_total: logisticTypeDynamic[i].request_total.value,
                  unit: logisticTypeDynamic[i].unit.value,
                  letter_upload,
                }).catch((err) => {
                  console.log(err);
                });
              }

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });

              toast.success("Data berhasil disimpan", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);

              this.setState({
                isLoading: false,
              });

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });

          break;
        case PageMode.edit:
          const payload = {
            ...basePayload,
            logistic_type: logisticTypeDynamic[0].logistic_type.value,
            request_total: logisticTypeDynamic[0].request_total.value,
            unit: logisticTypeDynamic[0].unit.value,
          };
          if (files.length > 0) {
            payload.file = files[0];
          }
          await LogisticReqStockService.update(
            payload,
            this.props.requestStock.id
          )
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Berhasil Diubah", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);

              this.setState({
                isLoading: false,
              });

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
          break;
      }
    }
  };

  onValidate = () => {
    let isValid = true;

    if (
      !this.validationHandler("year_submission", "Tahun pengajuan wajib diisi")
    ) {
      isValid = false;
    }
    if (!this.validationHandler("letter_date", "Tanggal surat wajib diisi")) {
      isValid = false;
    }
    if (!this.validationHandler("letter_number", "Nomor surat wajib diisi")) {
      isValid = false;
    }
    if (
      !this.validationHandler("request_type", "Tipe permohonan wajib diisi")
    ) {
      isValid = false;
    }
    if (this.state.files.length < 1 && this.props.mode === PageMode.add) {
      isValid = false;
      this.setState({ showFileError: true });
    }

    const customValidate = (item, msg) => {
      const cusValid = item.value !== "" && item.isValid;
      return {
        value: item.value,
        isValid: cusValid,
        message: cusValid ? "" : msg,
      };
    };
    const newLogisticTypeDynamic = this.state.logisticTypeDynamic.map((e) => {
      e.logistic_type = customValidate(
        e.logistic_type,
        "Jenis kosong atau tidak valid"
      );
      e.request_total = customValidate(
        e.request_total,
        "Jumlah permohonan wajib diisi"
      );
      e.unit = customValidate(e.unit, "Satuan wajib dipilih");

      if (!e.logistic_type.isValid) isValid = false;
      if (!e.request_total.isValid) isValid = false;
      if (!e.unit.isValid) isValid = false;
      return e;
    });
    this.setState({ logisticTypeDynamic: newLogisticTypeDynamic });
    return isValid;
  };

  splitVal = (val) => {
    return val?.split("|")[1];
  };

  getUnitOpt = (val) => {
    const listOpt = [];
    if (
      val === "DHP Dispersible" ||
      val === "DHP" ||
      val === "Primaquin" ||
      val === "Kina (Tablet)" ||
      val === "Doksisiklin"
    ) {
      listOpt.push({ value: "Tablet", label: "Tablet" });
    } else if (val === "Artesunat Inj") {
      listOpt.push({ value: "Vial", label: "Vial" });
    } else if (val === "Kina Inj (Ampul)") {
      listOpt.push({ value: "Ampul", label: "Ampul" });
    } else if (val === "RDT") {
      listOpt.push({ value: "Tes", label: "Tes" });
    } else if (val === "LLIN") {
      listOpt.push({ value: "Lembar", label: "Lembar" });
    } else if (val === "Insektisida" || val === "Larvasida") {
      listOpt.push({ value: "Kg", label: "Kg" });
      listOpt.push({ value: "Liter", label: "Liter" });
    } else if (val === "Lab Kit") {
      listOpt.push({ value: "Paket", label: "Paket" });
    } else if (
      val === "Giemsa" ||
      val === "Minyak Imersi" ||
      val === "Methanol"
    ) {
      listOpt.push({ value: "CC", label: "CC" });
    } else if (val === "Blood Slide") {
      listOpt.push({ value: "Box", label: "Box" });
    } else if (
      val === "Slide Box" ||
      val === "Spray Can" ||
      val === "Mist Blower" ||
      val === "Microscope Binocular" ||
      val === "Microscope Trinocular" ||
      val === "Microscope Teaching"
    ) {
      listOpt.push({ value: "Buah", label: "Buah" });
    } else if (val === "Blood Lancet") {
      listOpt.push({ value: "Box", label: "Box" });
    } else {
      listOpt.push({ value: "Buah", label: "Buah" });
      listOpt.push({ value: "Tablet", label: "Tablet" });
      listOpt.push({ value: "Vial", label: "Vial" });
      listOpt.push({ value: "Box", label: "Box" });
      listOpt.push({ value: "Kg", label: "Kg" });
      listOpt.push({ value: "Liter", label: "Liter" });
      listOpt.push({ value: "Tes", label: "Tes" });
      listOpt.push({ value: "CC", label: "CC" });
      listOpt.push({ value: "Paket", label: "Paket" });
      listOpt.push({ value: "Ampul", label: "Ampul" });
    }
    return listOpt;
  };

  setUnitOpt = (val) => {
    const list = this.state.listUnitOpt.find((x) => x.index === val);
    if (list) {
      return list.value;
    }
    return [];
  };

  validationHandler = (name, errorMsg) => {
    let isValid = true;
    if (
      this.state[name].value === "" ||
      this.state[name].value === null ||
      this.state[name].value === undefined
    ) {
      this.setState({
        [name]: {
          value: "",
          isValid: false,
          message: `${errorMsg}`,
        },
      });
      isValid = false;
    }
    return isValid;
  };

  render() {
    const {
      logisticTypeDynamic,
      showFileError,
      files,
      onEditFileName,
      province_id,
      district_id,
      fasyankes_id,
    } = this.state;
    const { mode, user } = this.props;
    const file = this.state.files.map((file) => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));

    return (
      <div className="form-wrapper">
        <div className="row">
          <div className="col-sm-6">
            <table className="table">
              <tr>
                <td>PROVINSI</td>
                <th>:</th>
                <th>
                  {this.splitVal(
                    mode !== PageMode.edit
                      ? user.province_id
                      : province_id.value
                  )}
                </th>
              </tr>
              <tr>
                <td>KAB/KOTA</td>
                <th>:</th>
                <th>
                  {this.splitVal(
                    mode !== PageMode.edit
                      ? user.district_id
                      : district_id.value
                  )}
                </th>
              </tr>
              <tr>
                <td>FASYANKES</td>
                <th>:</th>
                <th>
                  {this.splitVal(
                    mode !== PageMode.edit
                      ? user.fasyankes_id
                      : fasyankes_id.value
                  )}
                </th>
              </tr>
            </table>
          </div>
        </div>

        <div className="form-group">
          <p className="font-weight-bold">
            NOTE <span className="text-danger">*(WAJIB DIISI)</span>
          </p>
        </div>

        <InputDateTime
          label="Tahun pengajuan"
          name="year_submission"
          dateFormat="YYYY"
          timeFormat={false}
          onChange={(e) =>
            this.onInputChange({
              preventDefault: () => {},
              target: {
                name: "year_submission",
                value: moment(e).format("YYYY"),
              },
            })
          }
          placeholder="Pilih Tahun"
          closeOnClickOutside={true}
          value={this.state.year_submission.value}
          isInline={true}
          isRequired={true}
          hasError={!this.state.year_submission.isValid}
          errorMessage={this.state.year_submission.message}
          isDisabled={this.props.mode === PageMode.edit}
          isValidDate={(current) => current < moment("2025")}
        />
        <p
          className="alert-warning px-2"
          style={{
            marginLeft: "10px",
            marginRight: "10px",
            paddingTop: "6px",
          }}
        >
          <AlertTriangle className="mr-2" size={18} />
          <span>Catatan</span>
          <p style={{ paddingBottom: "6px" }}>
            Data tahun 2025, input melalui aplikasi SMILE.
          </p>
        </p>

        <InputDateTime
          label="Tanggal surat"
          name="letter_date"
          dateFormat="YYYY-MM-DD"
          timeFormat={false}
          onChange={(e) =>
            this.onInputChange({
              preventDefault: () => {},
              target: {
                name: "letter_date",
                value: moment(e).format("YYYY-MM-DD"),
              },
            })
          }
          placeholder="Pilih Tanggal"
          closeOnClickOutside={true}
          value={this.state.letter_date.value}
          isInline={true}
          isRequired={true}
          hasError={!this.state.letter_date.isValid}
          errorMessage={this.state.letter_date.message}
          isDisabled={this.props.mode === PageMode.edit}
          isValidDate={(current) => current < moment("2025")}
        />

        <InputField
          type="text"
          label="Nomor surat"
          name="letter_number"
          onChange={this.onInputChange}
          value={this.state.letter_number.value}
          placeholder="Nomor Surat"
          isRequired={true}
          hasError={!this.state.letter_number.isValid}
          errorMessage={this.state.letter_number.message}
          isDisabled={this.props.mode === PageMode.edit}
        />

        <InputSelect
          label="Jenis permintaan"
          name="request_type"
          placeholder="Pilih"
          data={[
            { value: "Tahunan", label: "Tahunan" },
            { value: "Insidentil", label: "Insidentil" },
          ]}
          onChange={this.onInputChange}
          value={this.state.request_type.value}
          isRequired={true}
          hasError={!this.state.request_type.isValid}
          errorMessage={this.state.request_type.message}
          isDisabled={this.props.mode === PageMode.edit}
        />

        {logisticTypeDynamic.map((item, idx) => {
          return (
            <div className="row" key={item.index}>
              <div className="col-3">
                <InputSelect
                  label="Jenis logistik"
                  name="logistic_type"
                  placeholder="Pilih"
                  data={[
                    { value: "DHP Dispersible", label: "DHP Dispersible" },
                    { value: "DHP", label: "DHP" },
                    { value: "Primaquin", label: "Primaquin" },
                    { value: "Artesunat Inj", label: "Artesunat Inj" },
                    { value: "Kina (Tablet)", label: "Kina tablet" },
                    { value: "Kina Inj (Ampul)", label: "Kina Inj" },
                    { value: "Doksisiklin", label: "Doksisiklin" },
                    { value: "RDT", label: "RDT" },
                    { value: "LLIN", label: "LLIN" },
                    { value: "Insektisida", label: "Insektisida" },
                    { value: "Larvasida", label: "Larvasida" },
                    { value: "Lab Kit", label: "Lab Kit" },
                    { value: "Giemsa", label: "Giemsa" },
                    { value: "Minyak Imersi", label: "Minyak Imersi" },
                    { value: "Blood Slide", label: "Blood Slide" },
                    { value: "Slide Box", label: "Slide Box" },
                    { value: "Blood Lancet", label: "Blood Lancet" },
                    { value: "Methanol", label: "Methanol" },
                    { value: "Spray Can", label: "Spray Can" },
                    { value: "Mist Blower", label: "Mist Blower" },
                    {
                      value: "Microscope Binocular",
                      label: "Microscope Binocular",
                    },
                    {
                      value: "Microscope Trinocular",
                      label: "Microscope Trinocular",
                    },
                    {
                      value: "Microscope Teaching",
                      label: "Microscope Teaching",
                    },
                  ]}
                  onChange={(e) => this.onLogisticChange(e, item)}
                  value={item.logistic_type.value}
                  isRequired={true}
                  hasError={!item.logistic_type.isValid}
                  errorMessage={item.logistic_type.message}
                  isDisabled={this.props.mode === PageMode.edit}
                />
              </div>
              <div className="col-4">
                <InputField
                  type="numberOnly"
                  label="Jumlah yang diminta"
                  name="request_total"
                  placeholder="Jumlah Yang Diminta"
                  onChange={(e) => this.onLogisticChange(e, item)}
                  value={item.request_total.value}
                  isRequired={true}
                  hasError={!item.request_total.isValid}
                  errorMessage={item.request_total.message}
                />
              </div>
              <div className="col-4">
                <InputSelect
                  label="Satuan"
                  name="unit"
                  placeholder="Pilih"
                  data={this.setUnitOpt(item.index)}
                  onChange={(e) => this.onLogisticChange(e, item)}
                  value={item.unit.value}
                  isRequired={true}
                  hasError={!item.unit.isValid}
                  errorMessage={item.unit.message}
                  isDisabled={
                    this.props.mode === PageMode.edit ||
                    !item.logistic_type.isValid
                  }
                />
              </div>
              {idx !== 0 && (
                <div className="col-1 align-self-center">
                  <div style={{ paddingTop: "30px" }}>
                    <button
                      className="btn btn-outline-danger btn-sm font-weight-bold mr-2"
                      onClick={() => {
                        const data = this.state.logisticTypeDynamic;
                        data.splice(idx, 1);
                        this.setState({ logisticTypeDynamic: data });
                      }}
                    >
                      <MinusCircle size={30} className="mr-2" /> Hapus
                    </button>
                  </div>
                </div>
              )}
            </div>
          );
        })}

        {mode !== "edit" && (
          <div className="form-group">
            <div className="row">
              <button
                style={{
                  border: "1px solid rgba(255, 0, 0, .5)",
                  borderStyle: "dashed",
                  marginRight: "15px",
                  marginLeft: "15px",
                  cursor: "pointer",
                }}
                className="btn btn-block"
                onClick={() => {
                  const data = this.state.logisticTypeDynamic;
                  const lastIdx = data[data.length - 1].index;
                  data.push({ ...logisticTypeAttr(), index: lastIdx + 1 });

                  const dataOpt = this.state.listUnitOpt;
                  dataOpt.push({
                    index: lastIdx + 1,
                    value: [],
                  });

                  this.setState({
                    logisticTypeDynamic: data,
                    listUnitOpt: dataOpt,
                  });
                }}
              >
                <PlusCircle /> Tambah Logistik
              </button>
            </div>
          </div>
        )}

        <div className="form-group">
          <label className="required">Upload surat</label>
          <Dropzone
            maxFiles={1}
            accept={"application/pdf"}
            onDrop={this.onDrop}
            maxSize={1024 * 300}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="container">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <div className="dz-message">
                    <div className="icon">
                      <i className="flaticon-file"></i>
                    </div>
                    <h5>Upload file dalam bentuk pdf</h5>
                    <div className="note">Drag & Drop or</div>
                    <button className="btn btn-teal">
                      Pilih File dari perangkat anda
                    </button>
                    <div className="note">Maksimal besar file: 300KB</div>
                  </div>
                </div>
                <aside>
                  <h4>Files</h4>
                  <ul>{file}</ul>
                  {files.length < 1 && mode === PageMode.edit && (
                    <ul>
                      <li>{onEditFileName}</li>
                    </ul>
                  )}
                  {showFileError && files.length < 1 && (
                    <small className="text-danger">File wajib dipilih</small>
                  )}
                </aside>
              </section>
            )}
          </Dropzone>
        </div>

        <div className="form-group d-flex justify-content-end">
          <Button onClick={() => this.props.changeMode("view")} isSecondary>
            Batalkan
          </Button>
          <Button
            onClick={this.onSave}
            isPrimary
            isDisabled={this.state.isLoading}
          >
            {this.state.isLoading ? "Mohon Tunggu..." : "Simpan Data"}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(Form);
