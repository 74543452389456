import Button from "components/atoms/Button";
import InputCheckbox from "components/atoms/InputCheckbox";
import InputField from "components/atoms/InputField";
import InputRadio from "components/atoms/InputRadio";
import InputSelect from "components/atoms/InputSelect";
import React, { useEffect, useState } from "react";
import { getCurrentUserFromLocalStorage } from "utils/common";
import {
  AgeTypeOptions,
  GenderOptions,
  PageMode,
  PregnancyStatusOptions,
  YesAndNoOptions,
  OAPOptions,
} from "utils/constant";

const PersonalDataStepForm = ({
  onNextStep,
  changeMode,
  formik,
  addressData,
  domicileData,
  handleAddressChange,
}) => {
  const [ageTypeDisable, setAgeTypeDisable] = useState(false);
  const [isBKK, setisBKK] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const user = getCurrentUserFromLocalStorage();
    const checkList = ["KKP", "BKK", "LKK", "WILKER"];
    checkList.forEach((e) => {
      if (user.first_name.includes(e)) setisBKK(true);
    });

  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Centang jika warna negara asing */}
      <InputCheckbox
        options={[{ label: "Centang Jika Warga Negara Asing", value: "Ya" }]}
        name="is_foreign_nationality"
        onChange={(e) => {
          const selectedValue = e.target.checked ? "Ya" : "Tidak";
          formik.handleChange("is_foreign_nationality")(selectedValue);
        }}
        onBlur={formik.handleBlur}
        selectedOption={formik.values.is_foreign_nationality}
      />

      {formik.values.not_have_ktp !== "Ya" && (
        <InputField
          label={"NIK"}
          name={"ktp"}
          placeholder={"Masukkan Nomor KTP/NIK"}
          maxLengthVal={16}
          type={"number"}
          {...formik.getFieldProps("ktp")}
          // error={formik?.errors?.ktp && formik?.touched?.ktp}
          // errorMessage={formik?.errors?.ktp}
          error={formik.touched.ktp && Boolean(formik.errors.ktp)}
          errorMessage={formik.errors.ktp}
          isRequired={true}
        />
      )}

      {/* Centang jika tidak memiliki ktp */}
      <InputCheckbox
        options={[{ label: "Centang Jika Tidak Memiliki KTP", value: "Ya" }]}
        name="not_have_ktp"
        onChange={(e) => {
          const selectedValue = e.target.checked ? "Ya" : "Tidak";
          formik.handleChange("not_have_ktp")(selectedValue);
          if (selectedValue !== "Ya") {
            formik.setFieldValue("ktp", "");
          }
        }}
        onBlur={formik.handleBlur}
        selectedOption={formik.values.not_have_ktp}
      />

      <div className="row">
        <div className="col-sm-4">
          <InputField
            label={"Nama"}
            name={"name"}
            {...formik.getFieldProps("name")}
            placeholder={"Masukkan nama"}
            error={formik.errors.name && formik.touched.name}
            errorMessage={formik.errors.name}
            isRequired={true}
          />
        </div>
        <div className="col-sm-4">
          <InputField
            label={"Usia"}
            name={"age"}
            type={"number"}
            placeholder={"Masukkan Usia"}
            maxLengthVal={2}
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (parseInt(selectedValue, 10) >= 12) {
                formik.setFieldValue("age_type", "Tahun");
                setAgeTypeDisable(true);
              } else {
                formik.setFieldValue("age_type", "Bulan");
                setAgeTypeDisable(false);
              }
              formik.handleChange("age")(selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.age}
            error={formik.errors.age && formik.touched.age}
            errorMessage={formik.errors.age}
            isRequired={true}
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            label={"Tahun/Bulan"}
            name={"age_type"}
            placeholder={"Pilih Tahun/Bulan"}
            data={AgeTypeOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("age_type")(selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={AgeTypeOptions?.find(
              (item) => item.value === formik.values.age_type
            )}
            error={formik.errors.age_type && formik.touched.age_type}
            errorMessage={formik.errors.age_type}
            isRequired={true}
            isDisabled={formik.values.age >= 12 ? true : false}
          />
        </div>
      </div>

      <InputSelect
        label={"Jenis Kelamin"}
        name={"gender"}
        placeholder={"Pilih Jenis Kelamin"}
        data={GenderOptions}
        onChange={(e) => {
          const selectedValue = e ? e.value : "";
          formik.handleChange("gender")(selectedValue);
          if (selectedValue !== "Perempuan") {
            formik.setFieldValue("pregnancy", "");
            formik.setFieldValue("discovery_activities", "");
            formik.setFieldValue("house_visit", "");
            formik.setFieldValue("isCadre", "");
            formik.setFieldValue("cadres_id", "");
          }
        }}
        onBlur={formik.handleBlur}
        value={GenderOptions?.find(
          (item) => item.value === formik.values.gender
        )}
        error={formik.errors.gender && formik.touched.gender}
        errorMessage={formik.errors.gender}
        isRequired={true}
      />

      <InputRadio
        label="Status Kependudukan (Khusus Papua)"
        name="oap"
        options={OAPOptions}
        onChange={(e) => {
          const selectedValue = e.target.value;
          formik.handleChange("oap")(selectedValue);
        }}
        onBlur={formik.handleBlur}
        selectedOption={formik.values.oap}
        error={formik.errors.oap && formik.touched.oap}
        errorMessage={formik.errors.oap}
        isRequired={true}
      />

      {formik.values.gender === "Perempuan" && formik.values.age >= 10 && (
        <InputSelect
          label={"Status Kehamilan"}
          name={"pregnancy"}
          placeholder={"Pilih Status Kehamilan"}
          data={PregnancyStatusOptions}
          onChange={(e) => {
            const selectedValue = e ? e.value : "";

            if (selectedValue === "Tidak Hamil") {
              formik.setFieldValue("discovery_activities", "");
              formik.setFieldValue("house_visit", "");
              formik.setFieldValue("isCadre", "");
              formik.setFieldValue("cadres_id", "");
            }

            formik.handleChange("pregnancy")(selectedValue);
          }}
          onBlur={formik.handleBlur}
          value={PregnancyStatusOptions?.find(
            (item) => item.value === formik.values.pregnancy
          )}
          error={formik.errors.pregnancy && formik.touched.pregnancy}
          errorMessage={formik.errors.pregnancy}
          isRequired={true}
        />
      )}

      <hr />

      <h3 className="subtitle-section">Alamat Domisili</h3>

      <div className="row">
        <div className="col-sm-6">
          <InputSelect
            label={"Provinsi"}
            name={"province_id"}
            placeholder={"Pilih Provinsi"}
            data={addressData.provincies}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("province_id")(selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={addressData.provincies?.find(
              (item) => item.value === formik.values.province_id
            )}
            error={formik.errors.province_id && formik.touched.province_id}
            errorMessage={formik.errors.province_id}
            isRequired={true}            
            isDisabled={!isBKK}
          />
        </div>
        <div className="col-sm-6">
          <InputSelect
            label={"Kabupaten/Kota"}
            name={"district_id"}
            placeholder={"Pilih Kabupaten"}
            data={addressData.districts}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("district_id")(selectedValue);
              if (selectedValue) {
                formik.setFieldValue("subdistrict_id", "");
                formik.setFieldValue("fasyankes_id", "");
                formik.setFieldValue("urbanvillage_id", "");
              }
            }}
            onBlur={formik.handleBlur}
            value={
              addressData.districts?.find(
                (item) => item.value === formik.values.district_id
              ) || ""
            }
            error={formik.errors.district_id && formik.touched.district_id}
            errorMessage={formik.errors.district_id}
            isRequired={true}
            isDisabled={!formik.values.province_id}
          />
        </div>
      </div>

      <div className="row">
        {/* {formik.values.district_id && ( */}
        <div className="col-sm-6">
          <InputSelect
            label={"Kecamatan"}
            name={"subdistrict_id"}
            placeholder={"Pilih Kecamatan"}
            data={addressData.subdistricts}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("subdistrict_id")(selectedValue);
              if (selectedValue) {
                formik.setFieldValue("fasyankes_id", "");
                formik.setFieldValue("urbanvillage_id", "");
              }
            }}
            onBlur={formik.handleBlur}
            value={
              addressData.subdistricts?.find(
                (item) => item.value === formik.values.subdistrict_id
              ) || ""
            }
            error={
              formik.errors.subdistrict_id && formik.touched.subdistrict_id
            }
            errorMessage={formik.errors.subdistrict_id}
            isRequired={true}
            isDisabled={!formik.values.district_id}
          />
        </div>
        {/* )} */}
        {/* {formik.values.subdistrict_id && ( */}
        <div className="col-sm-6">
          <InputSelect
            label={"Nama Fasyankes"}
            name={"fasyankes_id"}
            placeholder={"Pilih Fasyankes"}
            data={addressData.fasyankes}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("fasyankes_id")(selectedValue);
              if (selectedValue) {
                formik.setFieldValue("urbanvillage_id", "");
              }
            }}
            onBlur={formik.handleBlur}
            value={
              addressData.fasyankes?.find(
                (item) => item.value === formik.values.fasyankes_id
              ) || ""
            }
            error={formik.errors.fasyankes_id && formik.touched.fasyankes_id}
            errorMessage={formik.errors.fasyankes_id}
            isRequired={true}
            isDisabled={!formik.values.subdistrict_id}
          />
        </div>
        {/* )} */}
      </div>

      <div className="row">
        <div className="col-sm-6">
          <InputSelect
            label={"Nama Desa"}
            name={"urbanvillage_id"}
            placeholder={"Pilih Desa"}
            data={addressData.urbanvillages}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("urbanvillage_id")(selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={
              addressData.urbanvillages?.find(
                (item) => item.value === formik.values.urbanvillage_id
              ) || ""
            }
            error={
              formik.errors.urbanvillage_id && formik.touched.urbanvillage_id
            }
            errorMessage={formik.errors.urbanvillage_id}
            isRequired={true}
            isDisabled={!formik.values.fasyankes_id}
          />
        </div>
        <div className="col-sm-6">
          <InputField
            label={"Nama Dusun"}
            name={"sub_village"}
            placeholder={"Masukkan Nama Dusun"}
            {...formik.getFieldProps("sub_village")}
            error={formik.errors.sub_village && formik.touched.sub_village}
            errorMessage={formik.errors.sub_village}
            isRequired={true}
          />
        </div>
      </div>

      <InputField
        label={"Alamat Lengkap dan Nomor Telepon Pasien"}
        name={"address"}
        placeholder={"Masukkan Alamat Lengkap dan Nomor Telepon Pasien"}
        type={"textarea"}
        {...formik.getFieldProps("address")}
        error={formik.errors.address && formik.touched.address}
        errorMessage={formik.errors.address}
        isRequired={true}
      />

      <InputRadio
        label="Apakah Pasien Beralamat (KTP) Diluar Wilayah ?"
        name="is_domicile_outside"
        options={YesAndNoOptions}
        onChange={(e) => {
          const selectedValue = e.target.value;
          formik.handleChange("is_domicile_outside")(selectedValue);
        }}
        onBlur={formik.handleBlur}
        selectedOption={formik.values.is_domicile_outside}
        error={
          formik.errors.is_domicile_outside &&
          formik.touched.is_domicile_outside
        }
        errorMessage={formik.errors.is_domicile_outside}
        isRequired={true}
      />

      {formik.values.is_domicile_outside === "Ya" && (
        <>
          <InputSelect
            label={"Provinsi"}
            name={"domicile_province_id"}
            placeholder={"Pilih Provinsi"}
            data={domicileData.provincies}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("domicile_province_id")(selectedValue);
              if (selectedValue) {
                formik.setFieldValue("domicile_district_id", "");
                formik.setFieldValue("domicile_subdistrict_id", "");
                formik.setFieldValue("domicile_fasyankes_id", "");
              }
            }}
            onBlur={formik.handleBlur}
            value={
              domicileData.provincies?.find(
                (item) => item.value === formik.values.domicile_province_id
              ) || ""
            }
            error={
              formik.errors.domicile_province_id &&
              formik.touched.domicile_province_id
            }
            errorMessage={formik.errors.domicile_province_id}
            isRequired={true}
          />
          <InputSelect
            label={"Kabupaten/Kota"}
            name={"domicile_district_id"}
            placeholder={"Pilih Kabupaten/Kota"}
            data={domicileData.districts}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("domicile_district_id")(selectedValue);
              if (selectedValue) {
                formik.setFieldValue("domicile_subdistrict_id", "");
                formik.setFieldValue("domicile_fasyankes_id", "");
              }
            }}
            onBlur={formik.handleBlur}
            value={
              domicileData.districts?.find(
                (item) => item.value === formik.values.domicile_district_id
              ) || ""
            }
            error={
              formik.errors.domicile_district_id &&
              formik.touched.domicile_district_id
            }
            errorMessage={formik.errors.domicile_district_id}
            isRequired={true}
            isDisabled={!formik.values.domicile_province_id}
          />
          <InputSelect
            label={"Kecamatan"}
            name={"domicile_subdistrict_id"}
            placeholder={"Pilih Kecamatan"}
            data={domicileData.subdistricts}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("domicile_subdistrict_id")(selectedValue);
              if (selectedValue) {
                formik.setFieldValue("domicile_fasyankes_id", "");
              }
            }}
            onBlur={formik.handleBlur}
            value={
              domicileData.subdistricts?.find(
                (item) => item.value === formik.values.domicile_subdistrict_id
              ) || ""
            }
            isDisabled={!formik.values.domicile_district_id}
          />
          <InputSelect
            label={"Fasyankes"}
            name={"domicile_fasyankes_id"}
            placeholder={"Pilih Fasyankes"}
            data={domicileData.fasyankes}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              formik.handleChange("domicile_fasyankes_id")(selectedValue);
            }}
            onBlur={formik.handleBlur}
            value={
              domicileData.fasyankes?.find(
                (item) => item.value === formik.values.domicile_fasyankes_id
              ) || ""
            }
            isDisabled={!formik.values.domicile_subdistrict_id}
          />
        </>
      )}

      <hr />
      <div className="form_action">
        <Button
          variant={"tertiary"}
          size={"normal"}
          onClick={() => changeMode(PageMode.view)}
        >
          Batalkan
        </Button>
        <Button
          variant={"primary"}
          size={"normal"}
          onClick={onNextStep}
          isDisabled={
            (formik.values.not_have_ktp === "Tidak" && !formik.values.ktp) ||
            (formik.values.ktp !== "" && formik.errors.ktp) ||
            !formik.values.name ||
            !formik.values.age ||
            !formik.values.age_type ||
            !formik.values.gender ||
            !formik.values.oap ||
            !formik.values.province_id ||
            !formik.values.district_id ||
            !formik.values.subdistrict_id ||
            !formik.values.urbanvillage_id ||
            !formik.values.sub_village ||
            !formik.values.address ||
            !formik.values.is_domicile_outside ||
            (formik.values.is_domicile_outside === "Ya" &&
              !formik.values.domicile_province_id) ||
            (formik.values.is_domicile_outside === "Ya" &&
              !formik.values.domicile_district_id)
          }
        >
          Selanjutnya
        </Button>
      </div>
      <hr />
    </>
  );
};

export default PersonalDataStepForm;
