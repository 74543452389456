import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts/core";
import Column2D from "fusioncharts/charts";
import FusionTheme from "fusioncharts/themes/es/fusioncharts.theme.fusion";
import _ from "lodash";
import { indicatorAnalysisOptions } from "utils/constant";
import {
  ensureFullYear,
  formatWeeklyDates,
  monthList,
  monthListObject,
  numericFormatter,
  separateByYear,
  separateByYearAndEnsureFullYear,
} from "utils/common";

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

const cascadeFont = (str) =>
  (str || "").replace(/\b\w+/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

const Chart = ({ data, totalData, type, indicator, period, level, levelIdSelected }) => {
  const indicatorConfigTren = () => {
    let values = {};

    const uniqueWeeklyLabels = _.uniqBy(
      Object.values(data)?.map((r) => {
        return {
          label:
            period === "Mingguan" ? formatWeeklyDates(r?.dates) : `${r?.dates}`,
          year: r?.year,
        };
      }),
      "label"
    ).map((item) => ({ label: item.label }));

    const categories = [
      {
        category:
          period === "Tahunan" || period === "Bulanan"
            ? 
              period === "Tahunan" ? 
              monthList.map((r) => {
                return {
                  label: r,
                };
              }) : 
              data.map((item) => {
                const monthIndex = parseInt(item.dates?.split("-")[1], 10) - 1; 
                return {
                  label: monthList[monthIndex]
                };
              })
            : uniqueWeeklyLabels,
      },
    ];

    if (indicator === "indikator_3") {
      values = {
        categories: categories,
        dataset: Object.values(separateByYear(data)).map((r, i) => {
          return {
            seriesname: Object.keys(separateByYear(data))[i],
            renderas: "line",
            data: (
              ensureFullYear(r, Object.keys(separateByYear(data))[i], period == "Bulanan") || []
            ).map((res) => {
              return {
                value: res.death,
              };
            }),
          };
        }),
      };
    } else if (indicator === "indikator_16") {
      values = {
        categories: categories,
        dataset: Object.values(separateByYear(data)).map((r, i) => {
          return {
            seriesname: Object.keys(separateByYear(data))[i],
            renderas: "line",
            data: (
              ensureFullYear(r, Object.keys(separateByYear(data))[i], period == "Bulanan") || []
            ).map((res) => {
              return {
                value: res.value,
              };
            }),
          };
        }),
      };
    }
    return values;
  };

  const percentIndicator =
    indicator === "indikator_1" ||
    indicator === "indikator_4" ||
    indicator === "indikator_5" ||
    indicator === "indikator_6" ||
    indicator === "indikator_10" ||
    indicator === "indikator_11" ||
    indicator === "indikator_14" ||
    indicator === "indikator_15" ||
    indicator === "indikator_19" ||
    indicator === "indikator_25";
  const chartConfigs = {
    type: type || "column2d",
    width: "100%",
    height: "600",
    dataFormat: "json",
    dataSource: {
      chart: {
        plotToolText:
          indicator === "indikator_1" ||
          indicator === "indikator_4" ||
          indicator === "indikator_5" ||
          indicator === "indikator_9" ||
          indicator === "indikator_10" ||
          indicator === "indikator_14" ||
          indicator === "indikator_24" ||
          indicator === "indikator_6" ||
          indicator === "indikator_8" ||
          indicator === "indikator_11" ||
          indicator === "indikator_15" ||
          indicator === "indikator_19" ||
          indicator === "indikator_25"
            ? `$label, $value $displayValue`
            : indicator === "indikator_17" ||
              indicator === "indikator_18" ||
              indicator === "indikator_21" ||
              indicator === "indikator_22" ||
              indicator === "indikator_23" ||
              indicator === "indikator_23A" ||
              indicator === "indikator_27"
            ? `$label, $dataValue/${numericFormatter(data?.total)}`
            : indicator === "indikator_12"
            ? `$label, $dataValue/${numericFormatter(totalData)}`
            : "",
        drawAnchors: "1",
        drawCustomLegendIcon: "1",
        legendIconBorderThickness:
          indicator === "indikator_3" || indicator === "indikator_16"
            ? "12"
            : "3",
        exportEnabled: "1",
        exportFileName: `${indicatorAnalysisOptions.find((f) => f.value === indicator).label.replace(/ /g, "_")}_${levelIdSelected.replace(/ /g, "_")}_${moment().format('DDMMYYYY')}`,
        caption: "",
        subCaption: "",
        xAxisName: "",
        yAxisMaxValue: percentIndicator ? "100" : null,
        yAxisName:
          indicatorAnalysisOptions.find((f) => f.value === indicator).label ||
          "Kelengkapan %",
        numberSuffix: "",
        theme: "fusion",
        formatNumberScale: "0",
        labelDisplay: "rotate",
        slantLabel: "1",
        showLabels: "1",
        showValues: "1",
        valueFontSize: "12",
        rotateValues: "1",
        placevaluesinside: "0",
        canvasTopPadding: "100",
        numberSuffix: percentIndicator ? "%" : "",
      },
      ...indicatorConfigTren(),
      data:
        indicator === "indikator_17" ||
        indicator === "indikator_18" ||
        indicator === "indikator_21" ||
        indicator === "indikator_22" ||
        indicator === "indikator_23" ||
        indicator === "indikator_23A" ||
        indicator === "indikator_27"
          ? Object.keys(data?.case).map((r) => ({
              name:
                indicator === "indikator_21" && r === "Tanpa Petugas"
                  ? "NA"
                  : indicator === "indikator_17" && r === "pcd"
                  ? "PCD"
                  : indicator === "indikator_17" && r === "fup"
                  ? "FUP"
                  : indicator === "indikator_17" && r === "mbs"
                  ? "MBS"
                  : indicator === "indikator_17" && r === "surkon"
                  ? "Survei Kontak"
                  : indicator === "indikator_17" && r === "surkonpe"
                  ? "Survei Non PE"
                  : indicator === "indikator_17" && r === "kunrum"
                  ? "Kunjungan Rumah"
                  : indicator === "indikator_17" && r === "surmig"
                  ? "Surveilans Migras"
                  : indicator === "indikator_17" && r === "pusling"
                  ? "Pusling"
                  : indicator === "indikator_17" && r === "posyandu"
                  ? "Posyandu"
                  : indicator === "indikator_17" && r === "bumil"
                  ? "Skrining Bumil"
                  : indicator === "indikator_17" && r === "mtbs"
                  ? "Skrining Balita Sakit/MTBS"
                  : r,
              value: data?.case[r] || "0",
            }))
          : (data || []).map((r) => {
              let name = "";
              let value = "";
              if (indicator === "indikator_1") {
                if (level === "Fasyankes") {
                  name = cascadeFont(
                    monthListObject?.find((f) => f.value === r.name)?.label
                  );
                } else {
                  name = cascadeFont(r.name);
                }
                value = `${r.percent}%, ${numericFormatter(r.num)}/${numericFormatter(r.denum)}`;
              } else if (indicator === "indikator_2") {
                name = cascadeFont(r.name);
                value = r.positive_case;
              } else if (indicator === "indikator_3") {
                name = cascadeFont(r.name);
                value = r.death;
              } else if (indicator === "indikator_4") {
                name = cascadeFont(r.name);
                value = `${r.suspect_confirm}%, ${numericFormatter(r.num)}/${numericFormatter(r.denum)}`;
              } else if (indicator === "indikator_5") {
                name = cascadeFont(r.name);
                value = `${r.percent}%, ${numericFormatter(r.num)}/${numericFormatter(r.denum)}`;
              } else if (indicator === "indikator_6") {
                name = r.name;
                value = `${r.value}%, ${numericFormatter(r.num)}/${numericFormatter(r.denum)}`;
              } else if (indicator === "indikator_7") {
                name = cascadeFont(r.name);
                value = r.value;
              } else if (indicator === "indikator_8") {
                name = cascadeFont(r.name);
                value = `${r.percent}%, ${numericFormatter(r.num)}/${numericFormatter(r.denum)}`;
              } else if (indicator === "indikator_9") {
                name = r.name;
                value = `${r.percent}%, ${numericFormatter(r.num)}/${numericFormatter(r.denum)}`;
              } else if (indicator === "indikator_10") {
                name = r.name;
                value = `${r.value1}%, ${numericFormatter(r.num)}/${numericFormatter(r.denum)}`;
              } else if (indicator === "indikator_11") {
                name = cascadeFont(r.name);
                value = `${r.standard_medication}%, ${numericFormatter(r.num)}/${numericFormatter(r.denum)}`;
              } else if (indicator === "indikator_12") {
                name = cascadeFont(r.parasyte);
                value = `${r.total_kasus}`;
              } else if (indicator === "indikator_13") {
                name = cascadeFont(r.name);
                value = r.indigenous;
              } else if (indicator === "indikator_14") {
                name = r.name;
                value = `${r.logistic_completeness}%, ${numericFormatter(r.num)}/${numericFormatter(r.denum)}`;
              } else if (indicator === "indikator_15") {
                name = cascadeFont(r.name);
                value = `${r.value || r.percent}%, ${numericFormatter(r.num)}/${numericFormatter(r.denum)}`;
              } else if (indicator === "indikator_16") {
                name = cascadeFont(r.dates || "");
                value = r.value || "";
              } else if (indicator === "indikator_19") {
                name = cascadeFont(r.name || "");
                value = `${r.completeness}%, ${numericFormatter(r.num)}/${numericFormatter(r.denum)}`;
              } else if (indicator === "indikator_20") {
                name = cascadeFont(r.name || "");
                value = r.death || "";
              } else if (indicator === "indikator_24") {
                name = cascadeFont(r.name || "");
                value = `${r.api}, ${numericFormatter(r.num)}/${numericFormatter(r.denum)}`;
              } else if (indicator === "indikator_25") {
                name = r.name;
                value = `${r.percent || r.value}%, ${numericFormatter(r.num)}/${numericFormatter(r.denum)}`;
              }
              return {
                label: name,
                value: value,
                showLabel: "1",
              };
            }) || [],
    },
  };
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <ReactFC {...chartConfigs} />
  );
};

export default Chart;
