import React from "react";
import { monthConverter } from "utils/common";

const TableComponent = ({
  reportsData,
  tableRef,
  reportInfo,
  currentLevel,
}) => {
  const calculateTotal = () => {
    return reportsData.reduce(
      (total, item) => {
        total.total_row += 1;
        total.regmal2_tidak_periksa += parseInt(
          item.regmal2_tidak_periksa ?? 0
        );
        total.regmal1_tnpa_priksa += parseInt(item.regmal1_tnpa_priksa ?? 0);
        total.tot_mikroskop_regmal1_2_pe += parseInt(
          item.tot_mikroskop_regmal1_2_pe ?? 0
        );
        total.tot_rdt_regmal1_2_pe += parseInt(item.tot_rdt_regmal1_2_pe ?? 0);
        total.tot_mikroskop_rdt += parseInt(item.tot_mikroskop_rdt ?? 0);
        total.tot_suspek += parseInt(item.tot_suspek ?? 0);
        total.laki_umur1 += parseInt(item.laki_umur1 ?? 0);
        total.wanita_umur1 += parseInt(item.wanita_umur1 ?? 0);
        total.laki_umur2 += parseInt(item.laki_umur2 ?? 0);
        total.wanita_umur2 += parseInt(item.wanita_umur2 ?? 0);
        total.laki_umur3 += parseInt(item.laki_umur3 ?? 0);
        total.wanita_umur3 += parseInt(item.wanita_umur3 ?? 0);
        total.laki_umur4 += parseInt(item.laki_umur4 ?? 0);
        total.wanita_umur4 += parseInt(item.wanita_umur4 ?? 0);
        total.laki_umur5 += parseInt(item.laki_umur5 ?? 0);
        total.wanita_umur5 += parseInt(item.wanita_umur5 ?? 0);
        total.laki_umur6 += parseInt(item.laki_umur6 ?? 0);
        total.wanita_umur6 += parseInt(item.wanita_umur6 ?? 0);
        total.pos_laki += parseInt(item.pos_laki ?? 0);
        total.pos_wanita += parseInt(item.pos_wanita ?? 0);
        total.pos_laki_wanita += parseInt(item.pos_laki_wanita ?? 0);
        total.p_standar += parseInt(item.p_standar ?? 0);
        total.falsiparum += parseInt(item.falsiparum ?? 0);
        total.vivax += parseInt(item.vivax ?? 0);
        total.ovale += parseInt(item.ovale ?? 0);
        total.malariae += parseInt(item.malariae ?? 0);
        total.knowlesi += parseInt(item.knowlesi ?? 0);
        total.mix += parseInt(item.mix ?? 0);
        total.hamil_posmal += parseInt(item.hamil_posmal ?? 0);
        total.hamil_pengobatan_standar += parseInt(
          item.hamil_pengobatan_standar ?? 0
        );
        total.mtbs_posmal += parseInt(item.mtbs_posmal ?? 0);
        total.mtbs_pengobatan_standar += parseInt(
          item.mtbs_pengobatan_standar ?? 0
        );
        // total.kasus_pe += parseInt(item.kasus_pe ?? 0);
        total.indigenus += parseInt(item.indigenus ?? 0);
        total.impor += parseInt(item.impor ?? 0);
        total.indi_relap += parseInt(item.indi_relap ?? 0);
        total.impor_relap += parseInt(item.impor_relap ?? 0);
        total.induced += parseInt(item.induced ?? 0);
        total.kelambu_bumil += parseInt(item.kelambu_bumil ?? 0);
        total.nilai_kelengkapan += parseInt(item.nilai_kelengkapan ?? 0);
        total.nilaiketepatan += parseInt(item.nilaiketepatan ?? 0);
        total.edemis_population += parseInt(item.edemis_population) || 0;
        return total;
      },
      {
        total_row: 0,
        regmal2_tidak_periksa: 0,
        regmal1_tnpa_priksa: 0,
        tot_mikroskop_regmal1_2_pe: 0,
        tot_rdt_regmal1_2_pe: 0,
        tot_mikroskop_rdt: 0,
        tot_suspek: 0,
        laki_umur1: 0,
        wanita_umur1: 0,
        laki_umur2: 0,
        wanita_umur2: 0,
        laki_umur3: 0,
        wanita_umur3: 0,
        laki_umur4: 0,
        wanita_umur4: 0,
        laki_umur5: 0,
        wanita_umur5: 0,
        laki_umur6: 0,
        wanita_umur6: 0,
        pos_laki: 0,
        pos_wanita: 0,
        pos_laki_wanita: 0,
        p_standar: 0,
        falsiparum: 0,
        vivax: 0,
        ovale: 0,
        malariae: 0,
        knowlesi: 0,
        mix: 0,
        hamil_posmal: 0,
        hamil_pengobatan_standar: 0,
        mtbs_posmal: 0,
        mtbs_pengobatan_standar: 0,
        // kasus_pe: 0,
        indigenus: 0,
        impor: 0,
        indi_relap: 0,
        impor_relap: 0,
        induced: 0,
        kelambu_bumil: 0,
        nilai_kelengkapan: 0,
        nilaiketepatan: 0,
        edemis_population: 0,
      }
    );
  };

  const total = calculateTotal();

  let levelName = "";

  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";
  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  return (
    <div
      className="table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none" }}>
            <h3>SISMAL v3, TARIK DATA-1 PRIVATE</h3>
          </th>
        </tr>
      </table>
      <table>
        <tr style={{ border: "none" }}>
          <th
            width="200"
            style={{
              border: "none",
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            Periode Laporan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {periodeName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Tahun
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {reportInfo?.periode?.year}
          </th>
        </tr>
        {reportInfo?.statusPeriode === "monthly" && (
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {monthConverter(reportInfo?.periode?.month_start)} -{" "}
              {monthConverter(reportInfo?.periode?.month_end)}
            </th>
          </tr>
        )}
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Level
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {levelName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Provinsi
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.province
              ? "Semua Provinsi"
              : reportInfo?.province?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Kab/Kota
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.district
              ? "Semua Kab/Kota"
              : reportInfo?.district?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
          <th style={{ border: "none", paddingTop: 0 }}>
            : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
          </th>
        </tr>
      </table>
      <table>
        <thead>
          <tr>
            <th style={{ textAlign: "center", width: "80px" }}>No</th>
            {["national", "province"].includes(currentLevel) && (
              <th>Provinsi</th>
            )}
            <th>Kab/Kota</th>
            {["national", "province"].includes(currentLevel) && (
              <>
                <th>Endemisitas Tahun Sebelumnya</th>
                <th>Jumlah Penduduk</th>
              </>
            )}
            {["district", "fasyankes"].includes(currentLevel) && (
              <th>Fasyankes</th>
            )}
            {currentLevel === "district" && (
              <>
                <th>Endemisitas Tahun Sebelumnya</th>
                <th>Jumlah Penduduk</th>
              </>
            )}
            {currentLevel === "fasyankes" && <th>Jumlah Penduduk</th>}
            <th>
              Suspek Tanpa <br /> Pemeriksaan
            </th>
            <th>Presumtive</th>
            <th>
              Total Penemuan, <br /> Pemeriksaan Mikroskop
            </th>
            <th>
              Total Penemuan, <br /> Pemeriksaan RDT
            </th>
            <th>
              Total Penemuan, <br /> Pemeriksaan
            </th>
            <th>Total Suspek</th>
            <th>
              Pos Umur <br /> 0-11 bln, Laki-laki
            </th>
            <th>
              Pos Umur <br /> 0-11 bln, Perempuan
            </th>
            <th>
              Pos Umur <br /> 1-4 thn, Laki-Laki
            </th>
            <th>
              Pos Umur <br /> 1-4 thn, Perempuan
            </th>
            <th>
              Pos Umur <br /> 5-9 thn, Laki-Laki
            </th>
            <th>
              Pos Umur <br /> 5-9 thn, Perempuan
            </th>
            <th>
              Pos Umur <br /> 10-14 thn, Laki-Laki
            </th>
            <th>
              Pos Umur <br /> 10-14 thn, Perempuan
            </th>
            <th>
              Pos Umur <br /> 15-64 thn, Laki-Laki
            </th>
            <th>
              Pos Umur <br /> 15-64 thn, Perempuan
            </th>
            <th>
              Pos Umur <br /> {">"} 64 thn, Laki-Laki
            </th>
            <th>
              Pos Umur <br /> {">"} 64 thn, Perempuan
            </th>
            <th>
              Tot Pos <br /> Laki-Laki
            </th>
            <th>
              Tot Pos <br /> Perempuan
            </th>
            <th>
              Tot Pos <br /> L+P (19+20)
            </th>
            <th>
              Tot Pengobatan <br /> Standar
            </th>
            <th>Pos. Pf</th>
            <th>Pos. Pv</th>
            <th>Pos. Po</th>
            <th>Pos. Pm</th>
            <th>Probable Pk</th>
            <th>
              Pos. Mix <br /> (Lebih dari 2 Parasite)
            </th>
            <th>
              Pos_Malaria <br /> Ibu Hamil
            </th>
            <th>
              Pengobatan Standar <br /> Pos_malaria Ibu Hamil
            </th>
            <th>Pos_malaria MTBS</th>
            <th>
              Pengobatan <br /> Standar Pos_malaria MTBS
            </th>
            {/* <th>
              Kasus di PE <br /> (Faskes Pelaksana PE)
            </th> */}
            <th>Indigenous</th>
            <th>Impor</th>
            <th>Indigenous Relaps</th>
            <th>Import Relaps</th>
            <th>Induced</th>
            <th>Kelambu Ibu Hamil (KIA)</th>
            <th>Kelengkapan Laporan (%)</th>
            <th>Ketepatan Laporan (%)</th>
          </tr>
        </thead>
        <tbody>
          {reportsData ? (
            reportsData?.map((item, index) => (
              <tr key={item?.id}>
                <td>{index + 1}</td>
                {["national", "province"].includes(currentLevel) && (
                  <td>{item?.namaprop}</td>
                )}
                <td>{item?.namakab}</td>
                {["national", "province"].includes(currentLevel) && (
                  <>
                    <td>{item?.endemis_thn_sblm}</td>
                    <td>{item?.edemis_population}</td>
                  </>
                )}
                {["district", "fasyankes"].includes(currentLevel) && (
                  <td>{item?.namafaskes}</td>
                )}
                {currentLevel === "district" && (
                  <>
                    <td>{item?.endemis_thn_sblm}</td>
                    <td>{item?.edemis_population}</td>
                  </>
                )}
                {currentLevel === "fasyankes" && (
                  <td>{item?.edemis_population}</td>
                )}
                <td>{item?.regmal2_tidak_periksa ?? 0}</td>
                <td>{item?.regmal1_tnpa_priksa ?? 0}</td>
                <td>{item?.tot_mikroskop_regmal1_2_pe ?? 0}</td>
                <td>{item?.tot_rdt_regmal1_2_pe ?? 0}</td>
                <td>{item?.tot_mikroskop_rdt ?? 0}</td>
                <td>{item?.tot_suspek ?? 0}</td>
                <td>{item?.laki_umur1 ?? 0}</td>
                <td>{item?.wanita_umur1 ?? 0}</td>
                <td>{item?.laki_umur2 ?? 0}</td>
                <td>{item?.wanita_umur2 ?? 0}</td>
                <td>{item?.laki_umur3 ?? 0}</td>
                <td>{item?.wanita_umur3 ?? 0}</td>
                <td>{item?.laki_umur4 ?? 0}</td>
                <td>{item?.wanita_umur4 ?? 0}</td>
                <td>{item?.laki_umur5 ?? 0}</td>
                <td>{item?.wanita_umur5 ?? 0}</td>
                <td>{item?.laki_umur6 ?? 0}</td>
                <td>{item?.wanita_umur6 ?? 0}</td>
                <td>{item?.pos_laki ?? 0}</td>
                <td>{item?.pos_wanita ?? 0}</td>
                <td>{item?.pos_laki_wanita ?? 0}</td>
                <td>{item?.p_standar ?? 0}</td>
                <td>{item?.falsiparum ?? 0}</td>
                <td>{item?.vivax ?? 0}</td>
                <td>{item?.ovale ?? 0}</td>
                <td>{item?.malariae ?? 0}</td>
                <td>{item?.knowlesi ?? 0}</td>
                <td>{item?.mix ?? 0}</td>
                <td>{item?.hamil_posmal ?? 0}</td>
                <td>{item?.hamil_pengobatan_standar ?? 0}</td>
                <td>{item?.mtbs_posmal ?? 0}</td>
                <td>{item?.mtbs_pengobatan_standar ?? 0}</td>
                {/* <td>{item?.kasus_pe ?? 0}</td> */}
                <td>{item?.indigenus ?? 0}</td>
                <td>{item?.impor ?? 0}</td>
                <td>{item?.indi_relap ?? 0}</td>
                <td>{item?.impor_relap ?? 0}</td>
                <td>{item?.induced ?? 0}</td>
                <td>{item?.kelambu_bumil ?? 0}</td>
                <td>{Math.ceil(item?.nilai_kelengkapan) ?? 0}</td>
                <td>{Math.ceil(item?.nilaiketepatan) ?? 0}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={45}>Tidak Ada Data</td>
            </tr>
          )}
          <tr style={{ backgroundColor: "#43766C" }}>
            <td
              style={{ color: "#FFF" }}
              colSpan={currentLevel === "fasyankes" ? 3 : 4}
            >
              Total
            </td>
            <td style={{ color: "#FFF" }}>{total.edemis_population}</td>
            <td style={{ color: "#FFF" }}>{total.regmal2_tidak_periksa}</td>
            <td style={{ color: "#FFF" }}>{total.regmal1_tnpa_priksa}</td>
            <td style={{ color: "#FFF" }}>
              {total.tot_mikroskop_regmal1_2_pe}
            </td>
            <td style={{ color: "#FFF" }}>{total.tot_rdt_regmal1_2_pe}</td>
            <td style={{ color: "#FFF" }}>{total.tot_mikroskop_rdt}</td>
            <td style={{ color: "#FFF" }}>{total.tot_suspek}</td>
            <td style={{ color: "#FFF" }}>{total.laki_umur1}</td>
            <td style={{ color: "#FFF" }}>{total.wanita_umur1}</td>
            <td style={{ color: "#FFF" }}>{total.laki_umur2}</td>
            <td style={{ color: "#FFF" }}>{total.wanita_umur2}</td>
            <td style={{ color: "#FFF" }}>{total.laki_umur3}</td>
            <td style={{ color: "#FFF" }}>{total.wanita_umur3}</td>
            <td style={{ color: "#FFF" }}>{total.laki_umur4}</td>
            <td style={{ color: "#FFF" }}>{total.wanita_umur4}</td>
            <td style={{ color: "#FFF" }}>{total.laki_umur5}</td>
            <td style={{ color: "#FFF" }}>{total.wanita_umur5}</td>
            <td style={{ color: "#FFF" }}>{total.laki_umur6}</td>
            <td style={{ color: "#FFF" }}>{total.wanita_umur6}</td>
            <td style={{ color: "#FFF" }}>{total.pos_laki}</td>
            <td style={{ color: "#FFF" }}>{total.pos_wanita}</td>
            <td style={{ color: "#FFF" }}>{total.pos_laki_wanita}</td>
            <td style={{ color: "#FFF" }}>{total.p_standar}</td>
            <td style={{ color: "#FFF" }}>{total.falsiparum}</td>
            <td style={{ color: "#FFF" }}>{total.vivax}</td>
            <td style={{ color: "#FFF" }}>{total.ovale}</td>
            <td style={{ color: "#FFF" }}>{total.malariae}</td>
            <td style={{ color: "#FFF" }}>{total.knowlesi}</td>
            <td style={{ color: "#FFF" }}>{total.mix}</td>
            <td style={{ color: "#FFF" }}>{total.hamil_posmal}</td>
            <td style={{ color: "#FFF" }}>{total.hamil_pengobatan_standar}</td>
            <td style={{ color: "#FFF" }}>{total.mtbs_posmal}</td>
            <td style={{ color: "#FFF" }}>{total.mtbs_pengobatan_standar}</td>
            {/* <td style={{ color: "#FFF" }}>{total.kasus_pe}</td> */}
            <td style={{ color: "#FFF" }}>{total.indigenus}</td>
            <td style={{ color: "#FFF" }}>{total.impor}</td>
            <td style={{ color: "#FFF" }}>{total.indi_relap}</td>
            <td style={{ color: "#FFF" }}>{total.impor_relap}</td>
            <td style={{ color: "#FFF" }}>{total.induced}</td>
            <td style={{ color: "#FFF" }}>{total.kelambu_bumil}</td>
            <td style={{ color: "#FFF" }}>
              {Math.ceil(total.nilai_kelengkapan / total.total_row)}
            </td>
            <td style={{ color: "#FFF" }}>
              {Math.ceil(total.nilaiketepatan / total.total_row)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
