import React, { Component } from "react";
import { ErrorMessage, PageMode, requestStatus } from "utils/constant";

import LogisticOffStock from "services/LogisticOffStock";
import { Button, InputSelect } from "components";
import { connect } from "react-redux";
import InputDateTime from "components/InputDateTime";
import { toast } from "react-toastify";

const LogisticOffStockService = new LogisticOffStock();

class Form extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.defaultValue = {
      province_id: {
        value: this.props.user.province_id,
        isValid: true,
        message: "",
      },
      district_id: {
        value: this.props.user.district_id,
        isValid: true,
        message: "",
      },
      subdistrict_id: {
        value: this.props.user.subdistrict_id,
        isValid: true,
        message: "",
      },
      fasyankes_id: {
        value: this.props.user.fasyankes_id,
        isValid: true,
        message: "",
      },
      year: { value: "", isValid: true, message: "" },
      month: { value: "", isValid: true, message: "" },
      logistic_type: { value: "", isValid: true, message: "" },
      more_than_seven_day_off_stock: { value: "", isValid: true, message: "" },
      level: { value: "Fasyankes", isValid: true, message: "" },

      isLoading: false,
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  // logisticOpt = async (year) => {
  //   const check = await LogisticOffStockService.checkArte(year);
  //   if (check.delete_artesunat_inj) {
  //     this.setState({
  //       logistic_option: [
  //         { value: "DHP (Tablet)", label: "DHP (Tablet)" },
  //         { value: "Primaquin (Tablet)", label: "Primaquin (Tablet)" },
  //       ],
  //     });
  //   } else {
  //     this.setState({
  //       logistic_option: [
  //         { value: "DHP (Tablet)", label: "DHP (Tablet)" },
  //         { value: "Primaquin (Tablet)", label: "Primaquin (Tablet)" },
  //       ],
  //     });
  //   }
  // };

  componentDidMount() {
    this._isMounted = true;

    let obj = Object.assign({}, this.state);
    if (this.props.mode === PageMode.edit) {
      const { offStock } = this.props;

      obj.province_id.value = offStock.province_id;
      obj.district_id.value = offStock.district_id;
      obj.subdistrict_id.value = offStock.subdistrict_id;
      obj.fasyankes_id.value = offStock.fasyankes_id;
      obj.year.value = offStock.year;
      obj.month.value = offStock.month;
      obj.logistic_type.value = offStock.logistic_type;
      obj.more_than_seven_day_off_stock.value =
        offStock.more_than_seven_day_off_stock;
    }
    this.setState(obj);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, this.state[name]);
    obj.value = value;
    this.setState({
      [name]: obj,
    });
    // if (name == "year") this.logisticOpt(value);
  };

  onSave = async (e) => {
    e.preventDefault();
    const isValid = this.validationHandler();

    if (isValid) {
      this.setState({
        isLoading: true,
      });

      let payload = {
        province_id: this.state.province_id.value,
        district_id: this.state.district_id.value,
        subdistrict_id: this.state.subdistrict_id.value,
        fasyankes_id: this.state.fasyankes_id.value,
        year: this.state.year.value,
        month: this.state.month.value,
        logistic_type: this.state.logistic_type.value,
        more_than_seven_day_off_stock:
          this.state.more_than_seven_day_off_stock.value,
      };

      switch (this.props.mode) {
        case PageMode.add:
          await LogisticOffStockService.add(payload)
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Putus Stok Berhasil Ditambahkan", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);

              this.setState({
                isLoading: false,
              });

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });

          break;
        case PageMode.edit:
          await LogisticOffStockService.update(payload, this.props.offStock.id)
            .then((res) => {
              this.setState({
                isLoading: false,
              });

              this.setState(this.defaultValue, () => {
                this.props.changeMode("view");
              });
              toast.success("Data Putus Stok Berhasil Diubah", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              console.log(err);

              this.setState({
                isLoading: false,
              });

              if (err.response.status === requestStatus.BadRequest) {
                toast.error(ErrorMessage.UserFaskesNotHaveAccess, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Forbidden) {
                toast.error(ErrorMessage.UserNotVerified, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }

              if (err.response.status === requestStatus.Conflict) {
                toast.error(ErrorMessage.DataAlreadyExist, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (err.response.status === requestStatus.ServerError) {
                toast.error(ErrorMessage.InternalServerError, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });

          break;
      }
    }
  };

  validationHandler = () => {
    const { year, month, logistic_type, more_than_seven_day_off_stock } =
      this.state;

    let isValid = true;

    if (year.value === "") {
      year.isValid = false;
      year.message = "Tahun harus diisi";
      isValid = false;
    }

    if (month.value === "") {
      month.isValid = false;
      month.message = "Bulan harus diisi";
      isValid = false;
    }

    if (logistic_type.value === "") {
      logistic_type.isValid = false;
      logistic_type.message = "Tipe harus diisi";
      isValid = false;
    }

    if (more_than_seven_day_off_stock.value === "") {
      more_than_seven_day_off_stock.isValid = false;
      more_than_seven_day_off_stock.message = "Harus harus diisi";
      isValid = false;
    }

    this.setState({
      year,
      month,
      logistic_type,
      more_than_seven_day_off_stock,
    });

    return isValid;
  };

  render() {
    return (
      <div className="form-wrapper">
        <div className="row">
          <div className="col-sm-6">
            <table className="table">
              <tr>
                <td>PROVINSI</td>
                <th>:</th>
                <th>{this.props.user.province_id?.split("|")[1]}</th>
              </tr>
              <tr>
                <td>KAB/KOTA</td>
                <th>:</th>
                <th>{this.props.user.district_id?.split("|")[1]}</th>
              </tr>
              <tr>
                <td>FASYANKES</td>
                <th>:</th>
                <th>{this.props.user.fasyankes_id?.split("|")[1]}</th>
              </tr>
            </table>
          </div>
        </div>

        <p className="font-weight-bold d-flex justify-content-start">
          NOTE <span className="text-danger">*(WAJIB DIISI)</span>
        </p>

        <InputDateTime
          label="Tahun"
          name="year"
          dateFormat="YYYY"
          placeholder="Tahun"
          value={this.state.year.value}
          onChange={(e) =>
            this.onInputChange({
              target: {
                name: "year",
                isValid: true,
                value: moment(e).format("YYYY"),
              },
            })
          }
          isRequired={true}
          hasError={!this.state.year.isValid}
          errorMessage={this.state.year.message}
          isDisabled={this.props.mode === PageMode.edit}
        />

        <InputSelect
          label="Bulan"
          name="month"
          placeholder="Pilih Bulan"
          data={[
            { value: "01", label: "Januari" },
            { value: "02", label: "Februari" },
            { value: "03", label: "Maret" },
            { value: "04", label: "April" },
            { value: "05", label: "Mei" },
            { value: "06", label: "Juni" },
            { value: "07", label: "Juli" },
            { value: "08", label: "Agustus" },
            { value: "09", label: "September" },
            { value: "10", label: "Oktober" },
            { value: "11", label: "November" },
            { value: "12", label: "Desember" },
          ]}
          onChange={this.onInputChange}
          value={this.state.month.value}
          isRequired={true}
          hasError={!this.state.month.isValid}
          errorMessage={this.state.month.message}
          isDisabled={this.props.mode === PageMode.edit}
        />

        <InputSelect
          label="Jenis Logistiik"
          name="logistic_type"
          placeholder="Pilih Jenis Logistik"
          data={[
            { value: "DHP (Tablet)", label: "DHP (Tablet)" },
            { value: "Primaquin (Tablet)", label: "Primaquin (Tablet)" },
          ]}
          onChange={this.onInputChange}
          value={this.state.logistic_type.value}
          isRequired={true}
          hasError={!this.state.logistic_type.isValid}
          errorMessage={this.state.logistic_type.message}
          isDisabled={this.props.mode === PageMode.edit}
        />

        <InputSelect
          label="Lebih dari tujuh hari putus stok"
          name="more_than_seven_day_off_stock"
          placeholder="Pilih"
          data={[
            { value: "Ya", label: "Ya" },
            { value: "Tidak", label: "Tidak" },
          ]}
          onChange={this.onInputChange}
          value={this.state.more_than_seven_day_off_stock.value}
          isRequired={true}
          hasError={!this.state.more_than_seven_day_off_stock.isValid}
          errorMessage={this.state.more_than_seven_day_off_stock.message}
        />

        <div className="form-group d-flex justify-content-end">
          <Button onClick={() => this.props.changeMode("view")} isSecondary>
            Batalkan
          </Button>
          <Button
            onClick={this.onSave}
            isPrimary
            isDisabled={this.state.isLoading}
          >
            {this.state.isLoading ? "Mohon Tunggu..." : "Simpan Data"}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(Form);
