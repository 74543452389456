import React, { Component } from "react";
import { InputSelect, InputField, Button } from "components";
import { toast } from "react-toastify";
import PeKasus from "services/PeKasus";
import { PageMode } from "utils/constant";
import { errorToastNotif } from "utils/common";

const PeKasusService = new PeKasus();

export default class Modal extends Component {
  _isMounted = true;
  constructor(props) {
    super(props);
    this.defaultValue = {
      modalIsOpen: false,
      fasyankes_id: { value: "", isValid: true, message: "" },
      name: { value: "", isValid: true, message: "" },
      indigenous_status: { value: "", isValid: true, message: "" },
      indigenous_desc: { value: "", isValid: true, message: "" },
    };
    this.state = Object.assign({}, this.defaultValue);
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  componentDidMount() {
    this._isMounted = true;
    let obj = Object.assign({}, this.state);

    if (this.props.mode === PageMode.edit) {
      const { data } = this.props;
      obj.fasyankes_id.value = data.fasyankes_id;
      obj.name.value = data.name;
      obj.indigenous_status.value = data.indigenous_status;
      obj.indigenous_desc.value = data.indigenous_desc;
    }
    this.setState(obj);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onInputChange = (e) => {
    const { name, value } = e.target;
    let obj = Object.assign({}, this.state[name]);
    obj.value = value;
    this.setState({
      [name]: obj,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const isValid = this.validationHandler();
    if (isValid) {
      const { indigenous_status, indigenous_desc } = this.state;
      let payload = {
        indigenous_status: indigenous_status.value,
        indigenous_desc:
          indigenous_status.value === "Tidak" ? indigenous_desc.value : "",
      };

      await PeKasusService.update(payload, this.props.data.id)
        .then((res) => {
          this.setState(this.defaultValue, () => {
            this.props.changeMode("view");
          });
          toast.success("Data Indigenous Berhasil Di Validasi", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          console.log(err);
          errorToastNotif(err);
        });
    }
  };

  validationHandler = () => {
    const { indigenous_status, indigenous_desc } = this.state;
    let isValid = true;

    if (indigenous_status.value === "") {
      indigenous_status.isValid = false;
      indigenous_status.message = "Status harus diisi";
      isValid = false;
    }
    if (indigenous_status.value === "Tidak" && indigenous_desc.value === "") {
      indigenous_desc.isValid = false;
      indigenous_desc.message = "Deskripsi harus diisi";
      isValid = false;
    }

    return isValid;
  };

  render() {
    return (
      <>
        <div
          className="modal fade"
          id="validateModal"
          tabIndex={-1}
          aria-labelledby="validateModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h3
                  className="modal-title font-weight-bold"
                  id="validateModalLabel"
                >
                  Validasi Kasus Indigenous
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <table className="table table-responsive">
                  <tbody>
                    <tr>
                      <td>NAMA FASYANKES</td>
                      <td>:</td>
                      <td>{this.state.fasyankes_id.value?.split("|")[1]}</td>
                    </tr>
                    <tr>
                      <td>NAMA PASIEN</td>
                      <td>:</td>
                      <td>{this.state.name.value}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-12">
                    <InputSelect
                      label="Status Validasi"
                      name="indigenous_status"
                      placeholder="Pilih Status Validasi"
                      data={[
                        { value: "Ya", label: "Ya" },
                        { value: "Tidak", label: "Tidak" },
                      ]}
                      onChange={this.onInputChange}
                      value={this.state.indigenous_status.value}
                      isRequired={true}
                      hasError={!this.state.indigenous_status.isValid}
                      errorMessage={this.state.indigenous_status.message}
                    />

                    <InputField
                      label="Keterangan"
                      name="indigenous_desc"
                      placeholder="Keterangan"
                      type="textarea"
                      value={this.state.indigenous_desc.value}
                      onChange={this.onInputChange}
                      isRequired={true}
                      hasError={!this.state.indigenous_desc.isValid}
                      errorMessage={this.state.indigenous_desc.message}
                      isHidden={
                        this.state.indigenous_status.value === "Tidak"
                          ? false
                          : true
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  onClick={this.onSubmit}
                  isPrimary
                  disabled={
                    this.state.indigenous_status.value === "" ||
                    (this.state.indigenous_status.value === "Tidak" &&
                      this.state.indigenous_desc.value === "")
                  }
                  data-toggle="modal"
                  data-target="#validateModal"
                >
                  Simpan Data
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
